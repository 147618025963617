import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Config } from './types';
import { toast } from 'react-toastify';

const StyledImageWidget = styled.div``;
const StyledLink = styled.a``;

export const handleLinkClick = (linkType, editing) => {
  if (
    !editing &&
    linkType === 'Email' &&
    navigator.userAgent.includes('Mac OS')
  ) {
    toast('Apple Mail is not supported', {
      type: 'warning',
      theme: 'colored',
    });
  }
};

const ImageWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    backgroundImage,
    paddingData,
    borderData,
    lineColor,
    altText,
    height,
    visible,
    isCustomWidget,
    url,
    newTab,
    linkType,
  } = props.widgetState.config;
  const { editing } = props;

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <StyledLink
      href={editing || url === '' ? null : url}
      target={newTab ? '_blank' : ''}
      onClick={() => handleLinkClick(linkType, editing)}
    >
      <StyledImageWidget
        style={{
          marginTop: '0',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          paddingTop: `${paddingData?.top}px`,
          paddingRight: `${paddingData?.right}px`,
          paddingBottom: `${paddingData?.bottom}px`,
          paddingLeft: `${paddingData?.left}px`,
          borderWidth: `${borderData?.thickness}px`,
          borderStyle: 'solid',
          borderColor: lineColor,
          borderRadius: `${borderData?.radius}px`,
          height: height,
          width: '100%',
        }}
      >
        {backgroundImage?.url ? (
          <img
            style={{ height: '100%', width: '100%' }}
            src={backgroundImage?.url}
            draggable={false}
            alt={altText}
          />
        ) : (
          <img
            style={{ height: '100%', width: '100%' }}
            src={
              'https://cdn-stage.linkhealth.com/site-builder/horizon/image-placeholder.png'
            }
            alt=""
            draggable={false}
          />
        )}
      </StyledImageWidget>
    </StyledLink>
  );
};
export default ImageWidget;
