import { useRouteMatch } from 'react-router-dom';
import PageTemplates from './PageTemplates';
import Scrollbars from 'react-custom-scrollbars';

const PageTemplatesRoutes = () => {
  const pageTemplateMatch = useRouteMatch('/page-templates/:templateId');
  const pageTemplatesListMatch = useRouteMatch('/page-templates');
  let PageToRender = null;

  if (pageTemplateMatch?.isExact) {
    PageToRender = <h1>Edit Page Template placeholder</h1>;
  }

  if (pageTemplatesListMatch?.isExact) {
    PageToRender = <PageTemplates />;
  }

  return (
    <Scrollbars autoHeight autoHeightMin={550} style={{ padding: '0em 1.5em' }}>
      {PageToRender}
    </Scrollbars>
  );
};

export default PageTemplatesRoutes;
