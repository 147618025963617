import { ContentPadding, NavLink } from 'shared/widgets/shared/types';
import { Alignment } from 'shared/widgets/Text/types';
import { defaultTheme } from 'link-ui-react';
import { IDP } from 'shared/api/idx';

export enum DropType {
  NEW_WIDGET = 'NEW_WIDGET',
  EXISTING_WIDGET = 'EXISTING_WIDGET',
}

export enum NonIDPValue {
  All = 'all',
  Unauthenticated = 'unauthenticated',
}

export const cardStyleOptions = ['Spacious', 'Compact'];

export const validationTypes = [
  'none',
  'alphabet',
  'alphanumeric',
  'date',
  'email',
  'optumemail',
  'number',
];

export const HEX_REGEX = new RegExp(
  '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$'
);

export const validationRegex = {
  none: () => {
    return '';
  },
  alphabet: (value: string) => {
    const isValid = /^[a-zA-Z]+$/.test(value);
    return !isValid ? 'Please enter only letters' : '';
  },
  alphanumeric: (value: string) => {
    const isValid = /^[a-zA-Z0-9]+$/.test(value);
    return !isValid ? 'Please enter only letters and numbers' : '';
  },
  date: (value: string) => {
    const isValid = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/.test(
      value
    );
    return !isValid ? 'Please enter a valid date (mm/dd/yyyy)' : '';
  },
  email: (value: string) => {
    const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value);
    return !isValid ? 'Please enter a valid e-mail' : '';
  },
  optumemail: (value: string) => {
    const isValid = /^[a-zA-Z0-9._%+-]+@(optum|uhc|[a-zA-Z]+\.uhc)\.com$/.test(
      value
    );
    return !isValid ? 'Please enter a valid Optum/UHG e-mail' : '';
  },
  number: (value: string) => {
    const isValid = /^[0-9]+$/.test(value);
    return !isValid ? 'Please enter a valid number' : '';
  },
};

export const sizes = [1, 2, 3, 4, 5];

export const PDF_AND_WORD_REGEX = /^.*\.(pdf|PDF|docx|DOCX|doc|DOC)$/;

export const PDF_WORD_PNG_JPG_REGEX = /^.*\.(pdf|PDF|docx|DOCX|doc|DOC|png|PNG|jpg|JPG|jpeg|JPEG)$/;

export const JSON_REGEX = /^.*\.(json|JSON)$/;

export const VALID_UPLOAD_FILES =
  'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

// length property here is used to indicate upto what limit we can have the values populated.
export const buttonBorderRadius = Array.from({ length: 11 }, (_, v) => v * 5);

export enum Alerts {
  ALERT_ERROR_MESSAGE = 'Save Alerts Failed',
}
export const VIDEO_FILE_REGEX = /^.*\.(mov|mp4)$/;

export const NO_IDP = 'no_idp';

export const breadCrumbInfo =
  'Breadcrumbs will help your users quickly and easily navigate between their trail of pages. They can see the previous pages they visited and revisit them, and see where they are now. Example: Home > Feedback';
export enum LinkTypes {
  SELECTONE = 'Select One...',
  LINK = 'Link',
  FILE = 'File',
}

export const BLANK_TEMPLATE_NAME = 'Blank';
export const FORM_TEMPLATE_NAME = 'Form';
export const GENERAL_TEMPLATE_NAME = 'Landing Page';

export const createSiteThemeInfo =
  'Here, you can select a brand theme to pre-load colors into your palette. You can add your own custom colors to any brand theme.';

export const createSiteThemeInfo2 =
  "Or, if you'd like to start without a brand theme, select 'None' from the dropdown below. The theme can be changed at any time from your site's style panel.";

export const BRAND_THEMES = ['None', 'Optum', 'UHC', 'Rally'];

export const CornerRadiusSizes = Array.from(Array(11), (_, index) => index * 5);
export const BELOW = 'below';
export const ITALIC = 'Italic';
export const BOLD = 'Bold';

export const defaultContentPadding: ContentPadding = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 20,
};

export const IDP_VALUES_TO_USE: IDP[] = [
  {
    displayName: 'All',
    alias: '',
  },
  {
    alias: 'optum-id',
    displayName: 'Healthcare ID',
  },
  {
    alias: 'uhg-ms-id',
    displayName: 'UHG ID',
  },
];

export const linkOptions = ['None', 'URL', 'Page'];

export const lineDirectionOptions = ['Horizontal', 'Vertical'];

export const lineThicknessOptions = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

export const thicknessValues = {
  '1': 31,
  '2': 32,
  '3': 33,
  '4': 34,
  '5': 35,
  '6': 36,
  '7': 37,
  '8': 38,
  '9': 39,
  '10': 40,
};

export const fontSizes = [
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  24,
  28,
  32,
  36,
  40,
  48,
];

export const lineSpacingValues = [
  1,
  1.1,
  1.2,
  1.3,
  1.4,
  1.5,
  1.6,
  1.7,
  1.8,
  1.9,
  2,
  2.1,
  2.2,
  2.3,
  2.4,
  2.5,
];

export const defaultNavMenuItems: NavLink[] = [
  {
    id: 1,
    type: 'URL',
    url: 'https://www.google.com',
    displayText: 'Link 1',
    visible: true,
    parentSectionId: undefined,
    icon: {
      url: '',
      alt: '',
    },
  },
  {
    id: 2,
    type: 'URL',
    url: 'https://www.google.com',
    displayText: 'Link 2',
    visible: true,
    parentSectionId: undefined,
    icon: { url: '', alt: '' },
  },
  {
    id: 3,
    type: 'URL',
    url: 'https://www.google.com',
    displayText: 'Link 3',
    visible: true,
    parentSectionId: undefined,
    icon: { url: '', alt: '' },
  },
  {
    id: 4,
    displayText: 'Menu 1',
    url: 'None',
    isSection: true,
    visible: true,
    icon: { url: '', alt: '' },
    childOrder: [5],
  },
  {
    id: 5,
    type: 'Page',
    pageSelected: undefined,
    displayText: 'Page 1',
    visible: true,
    parentSectionId: 4,
    isSection: false,
    icon: { url: '', alt: '' },
  },
];

export const characterSpacingValues = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const backgroundStyles = ['None', 'Color', 'Image'];

export const buttonWidgetBackgroundStyles = ['None', 'Color'];

export const pageBackgroundStyles = ['None', 'Color', 'Gradient'];

export const feedbackStyles = ['Pinned Tab', 'Floating Button'];

export const defaultLinkOptions = ['None', 'Email', 'Page', 'File', 'URL'];

export const defaultTypographyData = {
  text: '',
  align: 'left' as Alignment,
  bold: false,
  italic: false,
  leftAlign: false,
  rightAlign: false,
  centerAlign: false,
  fontSize: 12,
  font: 'OptumSans',
  underline: false,
  strikeThrough: false,
  characterSpacing: 0,
  fontFamily: 'OptumSans',
  lineSpacing: 1,
  textColor: '',
  textHighlightColor: '',
  iconBackgroundColor: 'white',
};
export const defaultLinkData = {
  displayText: 'Enter display text',
  emailAddress: '',
  emailSubject: '',
  url: '',
  newTab: false,
  bold: false,
  italic: false,
  fontSize: 12,
  font: 'OptumSans',
  underline: true,
  strikeThrough: false,
  characterSpacing: 0,
  fontFamily: 'OptumSans',
  lineSpacing: 1,
  textColor: 'black',
  iconColor: 'black',
  iconBackgroundColor: 'white',
  linkType: 'None',
};
export const defaultSizeLocationData = {
  width: 180,
  height: 80,
  xLocation: 10,
  yLocation: 10,
  minWidth: 10,
  minHeight: 10,
};
export const defaultMyappsSizeLocationData = {
  width: 1040,
  height: 600,
  xLocation: 10,
  yLocation: 10,
  minWidth: 10,
  minHeight: 10,
};

export const defaultCalendarSizeLocationData = {
  width: 547,
  height: 467,
  xLocation: 10,
  yLocation: 10,
  minWidth: 10,
  minHeight: 10,
};

export const defaultBackgroundData = {
  backgroundData: { style: 'None', color: 'transparent' },
};
export const defaultBackgroundImageData = {
  backgroundImage: { url: '', alt: '' },
};
export const defaultPaddingData = {
  paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
};
export const defaultBorderData = {
  borderData: { thickness: 0, radius: 0 },
};
export const defaultBoxShadowData = {
  boxShadowData: { horizontal: 0, vertical: 0, blurRadius: 0, spreadRadius: 0 },
  boxShadowColor: 'transparent',
};
export const defaultSlidesData = {
  slides: [
    { id: 0, name: 'Slide #1' },
    { id: 1, name: 'Slide #2' },
    { id: 2, name: 'Slide #3' },
  ],
  slideArrowColor: `${defaultTheme.colors.aux.lightBlue}`,
  slideSelectedDotsColor: `${defaultTheme.colors.aux.lightBlue}`,
  slideUnselectedDotsColor: `${defaultTheme.colors.aux.lightGrey}`,
  autoPlayInterval: '500',
  autoPlayChecked: false,
  arrowsCheck: true,
  positionalDotsCheck: true,
};
export const defaultLinkWidgetHoverData = {
  font: 'OptumSans',
  fontFamily: 'OptumSans',
  fontSize: 12,
  bold: false,
  italic: false,
  underline: true,
  strikeThrough: false,
  textColor: 'black',
  lineSpacing: 1,
  characterSpacing: 0,
  uniqueId: '',
  borderData: {
    thickness: 0,
    radius: 0,
  },
  lineColor: 'black',
  iconBackgroundColor: '',
  backgroundData: {
    style: 'None',
    color: 'transparent',
  },
  paddingData: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};
export const thicknessMultiplier = 3;
export const min_dx = -3;
export const min_dy = -3;
export const widgetTypes = {
  Text: 'Text',
  Image: 'Image',
  VideoUpload: 'VideoUpload',
  Divider: 'Divider',
  Html: 'Html',
  Section: 'Section',
  Card: 'Card',
  Button: 'Button',
  Application: 'Application',
  Tabs: 'Tabs',
  AccordionWidget: 'AccordionWidget',
  SlideShow: 'SlideShow',
  Dropdown: 'Dropdown',
  Banner: 'Banner',
  Form: 'Form',
  RadioButton: 'Radio',
  Checkbox: 'Checkbox',
  TextInput: 'TextInput',
  RichText: 'RichText',
  Link: 'Link',
  Navigation: 'Navigation',
  FileUpload: 'FileUpload',
  LoginButton: 'LoginButton',
  uploadWidget: 'UploadWidget',
  calendarWidget: 'CalendarWidget',
};
export const CONDITIONAL_EMAIL_DIALOG_TITLE = 'Customize Conditions';

export const cardWidgetDefaultBodyText =
  'Body 2a dolor sit amet, consectetur adipiscing elit. Egestas facilisi varius ligula feugiat enim, commodo risus, fames. Nonsed suspendisse orci, magna.';

export const bannerWidgetDefaultBodyText =
  'Inline message content goes here, Inline message content goes here, Inline message content goes here, Inline message content goes here, Inline message content goes here, Inline message content goes here';
export const buttonRadiusMultiplier = 5;
export const tabsCardBodyText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin';
export const slideShowWidgetDefaultBodyText =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et';
export const previewImageTooltipContent =
  'This image will be displayed on the all sites page. Tip: Take a screenshot of your site and upload here for the easiest view of your website';
export const feedbackEnableTooltipContent =
  'This will enable the feedback widget on your site.';
export const feedbackStyleTooltipContent =
  'If you choose pinned tab, your feedback button will be displayed as a pinned tab on the right hand side of your screen. If you choose floating button, your feedback button will be displayed as a mini button that floats on the bottom righthand side of the browser.';
export const tenancyContent =
  'Please contact your product or engineering team to get your tenancy name if you do not see it here';
export const pageSlugContent =
  'A page slug refers to the end part of a URL after the backslash (“/”) that identifies the specific page or post. Each slug on your web pages needs to be unique. You can  preview the slug below while entering it in the textbox';

export const defaultTabWidget = [
  {
    widgetType: widgetTypes.Image,
    x: 5,
    y: 95,
    width: 1190,
    height: 498,
    minWidth: 100,
    minHeight: 40,
    backgroundImage: {
      url:
        'https://cdn-stage.linkhealth.com/site-builder/horizon/Tab1Background.png',
      alt: '',
    },
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 44,
    y: 190,
    text: 'Get the support you need in person, virtually or digitally',
    bold: true,
    width: 260,
    height: 110,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    fontSize: 24,
    tabIndex: 0,
    backgroundData: {
      style: 'Color',
      color: 'transparent',
    },
    backgroundColor: 'transparent',
  },
  {
    widgetType: widgetTypes.Card,
    x: 44,
    y: 360,
    width: 350,
    height: 194,
    minWidth: 180,
    minHeight: 40,
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 54,
    y: 374,
    text: 'Text Category 1',
    bold: true,
    width: 200,
    height: 40,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 54,
    y: 420,
    text: tabsCardBodyText,
    bold: false,
    width: 300,
    height: 75,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 54,
    y: 506,
    text: 'Getting Started >',
    bold: true,
    width: 200,
    height: 40,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
    textColor: '#196ece',
  },
  {
    widgetType: widgetTypes.Card,
    x: 421,
    y: 360,
    width: 350,
    height: 194,
    minWidth: 180,
    minHeight: 40,
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 431,
    y: 374,
    text: 'Text Category 1',
    bold: true,
    width: 200,
    height: 40,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 431,
    y: 420,
    text: tabsCardBodyText,
    bold: false,
    width: 300,
    height: 75,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 431,
    y: 506,
    text: 'Getting Started >',
    bold: true,
    width: 200,
    height: 40,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
    textColor: '#196ece',
  },
  {
    widgetType: widgetTypes.Card,
    x: 800,
    y: 360,
    width: 350,
    height: 194,
    minWidth: 180,
    minHeight: 40,
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 810,
    y: 374,
    text: 'Text Category 1',
    bold: true,
    width: 200,
    height: 40,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 810,
    y: 420,
    text: tabsCardBodyText,
    bold: false,
    width: 300,
    height: 75,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
  },
  {
    widgetType: widgetTypes.Text,
    x: 810,
    y: 506,
    text: 'Getting Started >',
    bold: true,
    width: 200,
    height: 40,
    minWidth: 180,
    minHeight: 40,
    align: 'none',
    tabIndex: 0,
    textColor: '#196ece',
  },
];
export const defaultTestHoverData: HoverData = {
  iconStyle: 'With Icon',
  icon: { alt: 'test', url: 'test' },
  iconPlacement: 'left',
  text: 'Test',
  font: 'OptumSans',
  fontFamily: 'OptumSans',
  fontSize: 12,
  bold: true,
  italic: true,
  underline: true,
  strikeThrough: true,
  textColor: 'red',
  textHighlightColor: 'red',
  align: 'center',
  leftAlign: false,
  centerAlign: true,
  rightAlign: false,
  lineSpacing: 1,
  characterSpacing: 0,
  displayText: 'Test',
  width: 100,
  height: 100,
  minWidth: 10,
  minHeight: 10,
  xLocation: 10,
  yLocation: 10,
  uniqueId: '1',
  borderData: {
    thickness: 3,
    radius: 10,
  },
  lineColor: '',
  ...defaultPaddingData,
  iconBackgroundColor: 'red',
};
export const widgetOutsideWarning =
  'You dragged an element outside the gridlines, some of your visitors won’t see it.';

export const FEEDBACK_PLACEHOLDER_MESSAGE = [
  'I am having an issue with...',
  'I would like to see...',
  'Anything else you would like to share?',
];

export const defaultPageContent = {
  root: {
    id: 'root',
    type: 'List',
    config: {
      bgcolor: 'transparent',
    },
    children: [
      'footerText1',
      'footerText2',
      'footerDivider',
      'headerLogoText',
      'headerText',
      'headerSection',
      'footerSection',
      'footerImage',
      'footerLink1',
      'footerLink2',
      'footerLink3',
      'loginButton',
      'navBar',
    ],
    permanent: true,
    defaultWidget: true,
  },
  headerSection: {
    id: 'headerSection',
    type: 'Section',
    defaultWidget: true,
    config: {
      visible: true,
      enableFullScreen: false,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      backgroundData: {
        style: 'Color',
        color: '#FBF9F4',
      },
      backgroundImage: {
        url: '',
        alt: '',
      },
      boxShadowData: {
        horizontal: 0,
        vertical: 4,
        blurRadius: 5,
        spreadRadius: 0,
      },
      boxShadowColor: 'lightgrey',
      width: 1275,
      height: 102,
      xLocation: 0,
      yLocation: -1,
      minWidth: 10,
      minHeight: 10,
      borderData: {
        thickness: 0,
        radius: 0,
      },
      uniqueId: 'section345',
      marginTop: 0,
    },
    children: ['headerLogoText', 'headerText', 'loginButton', 'navBar'],
    tabIndex: -1,
  },
  headerLogoText: {
    id: 'headerLogoText',
    type: 'Text',
    config: {
      visible: true,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      text: 'Optum',
      align: 'left',
      bold: true,
      italic: false,
      leftAlign: false,
      rightAlign: false,
      centerAlign: false,
      fontSize: 18,
      font: 'OptumSans',
      underline: false,
      strikeThrough: false,
      characterSpacing: 0,
      fontFamily: 'OptumSans',
      lineSpacing: 1,
      textColor: '#FF612B',
      textHighlightColor: '',
      iconBackgroundColor: '#d9f6fa',
      width: 93,
      height: 34,
      xLocation: 31,
      yLocation: 15,
      minWidth: 10,
      minHeight: 10,
      backgroundData: {
        style: 'None',
        color: 'transparent',
      },
      backgroundImage: {
        url: '',
        alt: '',
      },
      paddingData: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      borderData: {
        thickness: 0,
        radius: 0,
      },
      uniqueId: '123abc',
      lineColor: 'black',
      marginTop: 0,
    },
    children: [],
    defaultWidget: true,
    tabIndex: -1,
  },
  headerText: {
    id: 'headerText',
    type: 'Text',
    config: {
      visible: true,
      align: 'left',
      backgroundData: { style: 'None', color: 'transparent' },
      backgroundImage: { url: '', alt: '' },
      bold: true,
      borderData: { thickness: 0, radius: 0 },
      centerAlign: false,
      characterSpacing: 0,
      font: 'OptumSans',
      fontFamily: 'OptumSans',
      fontSize: 14,
      height: 29,
      iconBackgroundColor: '#d9f6fa',
      isHorizonWidget: true,
      italic: false,
      leftAlign: false,
      lineColor: 'black',
      lineSpacing: 1,
      marginTop: 0,
      minHeight: 10,
      minWidth: 10,
      paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
      rightAlign: false,
      spacing: { top: '', right: '', bottom: '', left: '' },
      strikeThrough: false,
      text: 'Optum Insight Developer',
      textColor: '#0C55B8',
      textHighlightColor: '',
      underline: false,
      uniqueId: 'headerText',
      width: 249,
      xLocation: 31,
      yLocation: 51,
    },
    children: [],
    defaultWidget: true,
    tabIndex: -1,
  },
  footerSection: {
    id: 'footerSection',
    type: 'Section',
    defaultWidget: true,
    config: {
      visible: true,
      enableFullScreen: false,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      backgroundData: {
        style: 'Color',
        color: '#FBF9F4',
      },
      backgroundImage: {
        url: '',
        alt: '',
      },
      boxShadowData: {
        horizontal: 0,
        vertical: 0,
        blurRadius: 0,
        spreadRadius: 0,
      },
      boxShadowColor: 'transparent',
      width: 1275,
      height: 231,
      xLocation: 0,
      yLocation: 830,
      minWidth: 10,
      minHeight: 10,
      borderData: {
        thickness: 0,
        radius: 0,
      },
      uniqueId: 'section345',
      marginTop: 0,
    },
    children: [
      'footerImage',
      'footerLink1',
      'footerDivider',
      'footerText2',
      'footerLink2',
      'footerLink3',
      'footerText1',
    ],
    tabIndex: -1,
  },
  footerImage: {
    id: 'footerImage',
    type: 'Image',
    defaultWidget: true,
    config: {
      visible: true,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      backgroundImage: {
        url: 'https://cdn-stage.linkhealth.com/site-builder/529/OptumLogo1.png',
        alt: 'OptumLogo1.png',
      },
      width: 83,
      height: 35,
      xLocation: 31,
      yLocation: 868,
      minWidth: 10,
      minHeight: 10,
      paddingData: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      borderData: {
        thickness: 0,
        radius: 0,
      },
      uniqueId: '123abc',
      lineColor: 'black',
      enableFullScreen: false,
      marginTop: 0,
      parentId: 'footerSection',
    },
    children: [],
    tabIndex: -1,
  },
  footerLink1: {
    id: 'footerLink1',
    type: 'Link',
    defaultWidget: true,
    config: {
      visible: true,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      displayText: 'Link1',
      url: '',
      newTab: false,
      bold: true,
      italic: false,
      fontSize: 12,
      font: 'OptumSans',
      underline: true,
      strikeThrough: false,
      characterSpacing: 0,
      fontFamily: 'OptumSans',
      lineSpacing: 1,
      textColor: '#0015fa',
      iconBackgroundColor: '#d9f6fa',
      checked: 'URL',
      paddingData: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      borderData: {
        thickness: 0,
        radius: 0,
      },
      backgroundData: {
        style: 'None',
        color: 'transparent',
      },
      backgroundImage: {
        url: '',
        alt: '',
      },
      uniqueId: '123abc',
      lineColor: 'black',
      width: 90,
      height: 40,
      xLocation: 207,
      yLocation: 867,
      minWidth: 20,
      minHeight: 20,
      iconColor: '#db1d1d',
      selectedPage: null,
      marginTop: 0,
      parentId: 'footerSection',
      currentTab: 0,
      hoverData: {
        font: 'OptumSans',
        fontFamily: 'OptumSans',
        fontSize: 12,
        bold: false,
        italic: false,
        underline: true,
        strikeThrough: false,
        textColor: '#0015fa',
        lineSpacing: 1,
        characterSpacing: 0,
        uniqueId: '1abc',
        borderData: {
          thickness: 0,
          radius: 10,
        },
        lineColor: '#ff0000',
        iconBackgroundColor: '',
        displayText: '',
        paddingData: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
    },
    children: [],
    tabIndex: -1,
  },
  footerDivider: {
    id: 'footerDivider',
    type: 'Divider',
    config: {
      visible: true,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      width: 1,
      height: 169,
      minWidth: 1,
      minHeight: 1,
      xLocation: 158,
      yLocation: 865,
      uniqueId: '123abc',
      paddingData: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      direction: 'Vertical',
      thickness: '10',
      lineColor: '#9D9F9F',
      enableFullScreen: false,
      parentId: 'footerSection',
      marginTop: 0,
    },
    children: [],
    defaultWidget: true,
    tabIndex: -1,
  },
  footerText2: {
    id: 'footerText2',
    type: 'Text',
    config: {
      visible: true,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      text: '© 2024 All rights reserved',
      align: 'none',
      bold: false,
      italic: false,
      leftAlign: false,
      rightAlign: false,
      centerAlign: false,
      fontSize: 12,
      font: 'OptumSans',
      underline: false,
      strikeThrough: false,
      characterSpacing: 0,
      fontFamily: 'OptumSans',
      lineSpacing: 1,
      textColor: '',
      textHighlightColor: '',
      iconBackgroundColor: '#d9f6fa',
      width: 101,
      height: 80,
      xLocation: 31,
      yLocation: 952,
      minWidth: 10,
      minHeight: 10,
      backgroundData: {
        style: 'None',
        color: 'transparent',
      },
      backgroundImage: {
        url: '',
        alt: '',
      },
      paddingData: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      borderData: {
        thickness: 0,
        radius: 0,
      },
      uniqueId: '123abc',
      lineColor: 'red',
      marginTop: 0,
      parentId: 'footerSection',
    },
    children: [],
    defaultWidget: true,
    tabIndex: -1,
  },
  footerLink2: {
    id: 'footerLink2',
    type: 'Link',
    config: {
      visible: true,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      displayText: 'Link2',
      currentTab: 0,
      url: '',
      newTab: true,
      bold: true,
      italic: false,
      fontSize: 12,
      font: 'OptumSans',
      underline: true,
      strikeThrough: false,
      characterSpacing: 0,
      fontFamily: 'OptumSans',
      lineSpacing: 1,
      textColor: '#0015fa',
      iconBackgroundColor: '#d9f6fa',
      checked: 'URL',
      paddingData: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      borderData: {
        thickness: 0,
        radius: 0,
      },
      backgroundData: {
        style: 'None',
        color: 'transparent',
      },
      backgroundImage: {
        url: '',
        alt: '',
      },
      uniqueId: '2abc',
      lineColor: 'black',
      width: 90,
      height: 40,
      xLocation: 310,
      yLocation: 867,
      minWidth: 20,
      minHeight: 20,
      iconColor: '#0016ff',
      selectedPage: null,
      marginTop: 0,
      parentId: 'footerSection',
      hoverData: {
        font: 'OptumSans',
        fontFamily: 'OptumSans',
        fontSize: 12,
        bold: true,
        italic: false,
        underline: true,
        strikeThrough: false,
        textColor: '#0015fa',
        lineSpacing: 1,
        characterSpacing: 0,
        uniqueId: '2abc',
        borderData: {
          thickness: 0,
          radius: 0,
        },
        lineColor: '#ff0000',
        iconBackgroundColor: '',
        displayText: '',
        paddingData: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
    },
    defaultWidget: true,
    children: [],
    tabIndex: -1,
  },
  footerLink3: {
    id: 'footerLink3',
    type: 'Link',
    config: {
      visible: true,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      displayText: 'Link3',
      url: '',
      newTab: true,
      bold: true,
      italic: false,
      fontSize: 12,
      font: 'OptumSans',
      underline: true,
      strikeThrough: false,
      characterSpacing: 0,
      fontFamily: 'OptumSans',
      lineSpacing: 1,
      textColor: '#0015fa',
      iconBackgroundColor: '#d9f6fa',
      checked: 'URL',
      paddingData: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      borderData: {
        thickness: 0,
        radius: 0,
      },
      backgroundData: {
        style: 'None',
        color: 'transparent',
      },
      backgroundImage: {
        url: '',
        alt: '',
      },
      uniqueId: '3abc',
      lineColor: 'black',
      width: 90,
      height: 40,
      xLocation: 412,
      yLocation: 867,
      minWidth: 20,
      minHeight: 20,
      iconColor: '#0016ff',
      selectedPage: null,
      marginTop: 0,
      parentId: 'footerSection',
      currentTab: 0,
      hoverData: {
        font: 'OptumSans',
        fontFamily: 'OptumSans',
        fontSize: 12,
        bold: true,
        italic: false,
        underline: true,
        strikeThrough: false,
        textColor: '#0015fa',
        lineSpacing: 1,
        characterSpacing: 0,
        uniqueId: '3abc',
        borderData: {
          thickness: 0,
          radius: 0,
        },
        lineColor: '#ff0000',
        iconBackgroundColor: '',
        displayText: '',
        paddingData: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
    },
    defaultWidget: true,
    children: [],
    tabIndex: -1,
  },
  footerText1: {
    id: 'footerText1',
    type: 'Text',
    config: {
      visible: true,
      spacing: {
        top: '',
        right: '',
        bottom: '',
        left: '',
      },
      isHorizonWidget: true,
      text:
        'This is placeholder text. This is where you should place your legal text and/or privacy notice. It is recommended that you consult with your legal advisors for text that is relevant and adequate for your context and use. UI Toolkit cannot offer advice on legal text or privacy notices, this is outside our remit as a design system. Thank you for using UI Toolkit.',
      align: 'none',
      bold: false,
      italic: false,
      leftAlign: false,
      rightAlign: false,
      centerAlign: false,
      fontSize: 12,
      font: 'OptumSans',
      underline: false,
      strikeThrough: false,
      characterSpacing: 0,
      fontFamily: 'OptumSans',
      lineSpacing: 1,
      textColor: '',
      textHighlightColor: '',
      iconBackgroundColor: '#d9f6fa',
      width: 1040,
      height: 80,
      xLocation: 207,
      yLocation: 949,
      minWidth: 10,
      minHeight: 10,
      backgroundData: {
        style: 'None',
        color: 'transparent',
      },
      backgroundImage: {
        url: '',
        alt: '',
      },
      paddingData: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      borderData: {
        thickness: 0,
        radius: 0,
      },
      uniqueId: '123abc',
      lineColor: 'red',
      marginTop: 0,
      parentId: 'footerSection',
    },
    children: [],
    defaultWidget: true,
    tabIndex: -1,
  },
  loginButton: {
    id: 'loginButton',
    type: 'LoginButton',
    copyable: false,
    defaultWidget: true,
    config: {
      visible: true,
      backgroundImage: { url: '', alt: '' },
      backgroundData: { style: 'None', color: 'transparent' },
      borderData: { thickness: 0, radius: 0 },
      paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
      uniqueId: 'loginButton',
      width: 150,
      height: 75,
      xLocation: 1094,
      yLocation: 12,
      minWidth: 20,
      minHeight: 10,
      signInOptionEnabled: 'notEnabled',
      signInOptionTitle: 'Sign In Options',
      signInLinkColor: 'black',
      signInOptionLinks: [],
      lineColor: 'black',
      signInType: 'button',
      signInIcon: 'MoreVertical',
      fontSize: 12,
      font: 'OptumSans',
      buttonColor: '#c0e9ff',
      bold: false,
      italic: false,
      underline: false,
      strikeThrough: false,
      simulateLoggedOffUser: true,
      isHorizonWidget: true,
      parentId: 'headerSection',
    },
    children: [],
    tabIndex: -1,
  },
  navBar: {
    id: 'navBar',
    type: 'Navigation',
    defaultWidget: true,
    config: {
      visible: true,
      backgroundData: { style: 'Color', color: '#FBF9F4' },
      backgroundImage: { url: '', alt: '' },
      borderData: { radius: 0, thickness: 0 },
      dividerLineColor: '#DADBDC',
      enableFullScreen: false,
      font: 'OptumSans',
      fontSize: 12,
      height: 75,
      width: 129,
      xLocation: 965,
      yLocation: 12,
      minHeight: 30,
      minWidth: 40,
      iconColor: 'black',
      isHorizonWidget: true,
      lineColor: 'black',
      menuItems: [
        {
          displayText: 'Home',
          icon: { alt: '', url: '' },
          id: 1,
          isSection: false,
          pageSelected: null,
          type: 'page',
          parentSectionId: null,
          url: '/home',
          visible: true,
        },
      ],
      showDividerLines: true,
      paddingData: { top: 0, bottom: 0, left: 0, right: 0 },
      style: 'Horizontal',
      textColor: 'black',
      treeOrder: [1],
      parentId: 'headerSection',
    },
    children: [],
    tabIndex: -1,
  },
};

export const defaultRichTextEditorData = {};

export const FOLLOW_UP_MESSAGE =
  'You can expect a response from our team within ';

export const DEFAULT_FOLLOW_UP_MESSAGE =
  'Our team looks forward to being in touch soon';
