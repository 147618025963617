import { getWidget } from '../../../shared/widgets';
import React from 'react';
import { User } from 'oidc-client';
import { widgetTypes } from '../../../builder/util/constants';

export interface WidgetData {
  id: string;
  dynamic?: boolean;
  fullWidth?: boolean;
  height: number;
  isParentFullWidth?: boolean;
  parentId?: string;
  type: string;
  visible?: boolean;
  width: number;
  x: number;
  y: number;
  content: React.ReactNode;
  children: string[];
}

export function adjustWidgetYLocationsForBanner(
  widgets: WidgetData[]
): WidgetData[] {
  const headerWidget = widgets.find(w => w.id === 'headerSection');
  const bannerWidget = widgets.find(
    widget => widget.type === widgetTypes.Banner
  );
  return widgets.map(widget => {
    if (
      bannerWidget &&
      widget.id !== 'headerSection' &&
      !headerWidget.children.includes(widget.id) &&
      !bannerWidget.children.includes(widget.id) &&
      widget.id !== bannerWidget.id
    ) {
      return {
        ...widget,
        y: widget.y + bannerWidget.height,
      };
    } else {
      return widget;
    }
  });
}

export function isDynamicWidget(widgetType: string): boolean {
  const dynamicWidgetTypes = new Set([
    widgetTypes.AccordionWidget,
    widgetTypes.Application,
    widgetTypes.RichText,
    widgetTypes.Navigation,
    widgetTypes.Text,
    widgetTypes.Tabs,
  ]);
  return dynamicWidgetTypes.has(widgetType);
}

export function isChildOfFullWidthParent(
  widgetId: string,
  fullWidthParents: Set<string>,
  currentPage: PageVersion
): boolean {
  for (const parentId of fullWidthParents) {
    const parentWidget = currentPage.content[parentId];
    if (parentWidget.children.includes(widgetId)) {
      return true;
    }
  }
  return false;
}

export function processWidgetData(
  currentPage: PageVersion,
  handleUpdateConfig: {
    (config: any, widgetId: string, defaultWidget: boolean): void;
    (value: any, widgetId: string, defaultWidget: boolean): void;
  },
  user: User,
  pages: Page[],
  siteId: number,
  initiateLogout: { (): void; (): void }
): WidgetData[] {
  let widgets: WidgetData[] = [];
  let data = currentPage.content;
  const fullWidthParents = new Set<string>();

  for (const key in data) {
    const widget = currentPage.content[data[key].id];
    if (widget.id === 'root') {
      continue;
    }
    if (
      widget.config.enableFullScreen ||
      widget.id === 'headerSection' ||
      widget.id === 'footerSection'
    ) {
      fullWidthParents.add(widget.id);
    }

    const WidgetComponent = getWidget(widget.type, true).components.render;
    const isParentFullWidth = isChildOfFullWidthParent(
      widget.id,
      fullWidthParents,
      currentPage
    );

    const content = !widget.config.isCustomWidget ? (
      <WidgetComponent
        className={widget.id}
        currentPage={currentPage}
        widgetState={currentPage.content[widget.id]}
        onChange={handleUpdateConfig}
        value={currentPage.content[widget.id].config}
        editing={false}
        siteId={siteId}
        pages={pages}
        user={user}
        initiateLogout={initiateLogout}
      />
    ) : (
      <></>
    );

    widgets.push({
      id: widget.id,
      type: widget.type,
      height: widget.config.height,
      width: widget.config.width,
      x: Math.round(widget.config.xLocation),
      y: Math.round(widget.config.yLocation),
      dynamic: isDynamicWidget(widget.type),
      fullWidth: widget.config.enableFullScreen,
      isParentFullWidth: isParentFullWidth,
      visible: widget.config.visible,
      children: widget.children,
      content: content,
    });
  }
  // Adjust all widgets y position to account for the height of the banner widget
  widgets = adjustWidgetYLocationsForBanner(widgets);
  return widgets;
}
