import { LexicalEditor } from 'lexical';
// Todo: Add in support for direct markdown editing view. Disabling for now because toggling between views removes
// any styles from the editor that is not supported via markdown. This will be confusing for users. A follow up
// feature ticket can be created to determine proper UX that alerts users of this behavior and allows them to see
// that toggling between the two views has a destructive behavior if they have non compliant markdown nodes in the editor.
// import { $createCodeNode, $isCodeNode } from '@lexical/code';
// import {
//   $convertFromMarkdownString,
//   $convertToMarkdownString,
// } from '@lexical/markdown';
// import { MARKDOWN_TRANSFORMERS } from '../MarkdownTransformers';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  // $createTextNode,
  $getRoot,
  $isParagraphNode,
  CLEAR_EDITOR_COMMAND,
} from 'lexical';
import { useEffect, useState } from 'react';

import useModal from '../../hooks/useModal';
import { Button } from '@uitk/react';
import {
  IconEdit,
  IconFileCheck,
  // IconMarkdown,
  IconTrash,
} from '@tabler/icons-react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;

  .uitk-button {
    margin-right: 7px;
    margin-top: 70px;
    font-family: OptumSans;
  }
`;

export default function ActionsPlugin({
  // shouldPreserveNewLinesInMarkdown,
  handleEditingRichText,
  isEditingRichText,
}: {
  isRichText: boolean;
  // shouldPreserveNewLinesInMarkdown: boolean;
  handleEditingRichText: (isEditingRichText: boolean) => void;
  isEditingRichText: boolean;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);
  const [modal, showModal] = useModal();

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener(editable => {
        setIsEditable(editable);
      })
    );
  }, [editor]);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        const root = $getRoot();
        const children = root.getChildren();

        if (children.length > 1) {
          setIsEditorEmpty(false);
        } else {
          if ($isParagraphNode(children[0])) {
            const paragraphChildren = children[0].getChildren();
            setIsEditorEmpty(paragraphChildren.length === 0);
          } else {
            setIsEditorEmpty(false);
          }
        }
      });
    });
  }, [editor, isEditable]);

  // const handleMarkdownToggle = useCallback(() => {
  //   editor.update(() => {
  //     const root = $getRoot();
  //     const firstChild = root.getFirstChild();
  //     if ($isCodeNode(firstChild) && firstChild.getLanguage() === 'markdown') {
  //       $convertFromMarkdownString(
  //         firstChild.getTextContent(),
  //         MARKDOWN_TRANSFORMERS,
  //         undefined, // node
  //         shouldPreserveNewLinesInMarkdown
  //       );
  //     } else {
  //       const markdown = $convertToMarkdownString(
  //         MARKDOWN_TRANSFORMERS,
  //         undefined, //node
  //         shouldPreserveNewLinesInMarkdown
  //       );
  //       const codeNode = $createCodeNode('markdown');
  //       codeNode.append($createTextNode(markdown));
  //       root.clear().append(codeNode);
  //       if (markdown.length === 0) {
  //         codeNode.select();
  //       }
  //     }
  //   });
  // }, [editor, shouldPreserveNewLinesInMarkdown]);

  return (
    <div className="actions">
      {isEditingRichText && (
        <>
          <button
            className="action-button clear"
            data-test-id="action-button-clear"
            disabled={isEditorEmpty}
            onClick={() => {
              showModal('Clear Editor', onClose => (
                <ShowClearDialog editor={editor} onClose={onClose} />
              ));
            }}
            title="Clear"
            aria-label="Clear editor contents"
          >
            <IconTrash size={18} stroke={2} />
          </button>
          {/*
            Todo: Disabling Markdown toggle for now. Toggling to markdown strips styles from the rich text and this
            will be confusing for users. Users can still use normal markdown syntax in the editor if they wish to.
            Ideally we re-enable this feature when we have a way to preserve styles when toggling to markdown and back.
            This is out of scope of the current feature work though .
          */}
          {/*<button*/}
          {/*  className="action-button"*/}
          {/*  onClick={handleMarkdownToggle}*/}
          {/*  title="Convert From Markdown"*/}
          {/*  aria-label="Convert from markdown"*/}
          {/*>*/}
          {/*  <IconMarkdown size={18} stroke={2} />*/}
          {/*</button>*/}
          <button
            className="action-button save-edit"
            data-test-id="action-button-save-edit"
            onClick={() => handleEditingRichText(!isEditingRichText)}
            title="Save Text"
            aria-label="Save Text"
          >
            <IconFileCheck size={18} stroke={2} />
          </button>
        </>
      )}
      <button
        // Had to use inline style to hide the button because adding or removing a class causes a FOUC in the editor
        // that seems to be associated with state changes
        style={isEditingRichText ? { display: 'none' } : {}}
        className="action-button"
        onClick={() => handleEditingRichText(!isEditingRichText)}
        title="Edit Text"
        aria-label="Edit Text"
      >
        <IconEdit size={18} stroke={2} />
      </button>
      {modal}
    </div>
  );
}

function ShowClearDialog({
  editor,
  onClose,
}: {
  editor: LexicalEditor;
  onClose: () => void;
}): JSX.Element {
  return (
    <>
      Are you sure you want to clear the editor?
      <div className="Modal__content">
        <ButtonWrapper>
          <Button
            data-test-id="clear-editor-clear-button"
            size="m"
            onClick={() => {
              editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
              editor.focus();
              onClose();
            }}
          >
            Clear
          </Button>
          <Button
            variant="tertiary"
            className={'mt-base'}
            data-test-id="clear-editor-cancel-button"
            size="m"
            onClick={() => {
              editor.focus();
              onClose();
            }}
          >
            Cancel
          </Button>
        </ButtonWrapper>
      </div>
    </>
  );
}
