import { Fragment, SFC } from 'react';
import styled from 'styled-components';
import { EnhancedTile } from '../types';
import Div from 'shared/components/Div';
import { defaultTheme as theme } from 'link-ui-react';

const AppWrapper = styled(Div)`
  display: flex;
  width: 360px;
  height: 350px;
  flex: 0 0 360px;
  border: 2px solid ${theme.colors.aux.blueGrey};
  border-radius: 4px;
  overflow: hidden;
  margin: 0 15px;
  margin-bottom: 75px;
`;

const Iframe = styled.iframe.attrs({
  width: '360px',
  height: '350px',
})``;

const RichAppList: SFC<{ tiles: Array<EnhancedTile> }> = ({ tiles }) => (
  <Fragment>
    {tiles.map(tile => (
      <AppWrapper key={tile.tileId}>
        <Iframe
          data-test-id={`tile-iframe-${tile.tileId}`}
          src={tile.iframesrc}
          scrolling="no"
        />
      </AppWrapper>
    ))}
  </Fragment>
);

export default RichAppList;
