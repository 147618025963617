import { useEffect, useState } from 'react';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, $insertNodes } from 'lexical';

interface CustomOnChangePluginProps {
  value: string;
  onChange: (value: string) => void;
}

/**
 * CustomOnChangePlugin is a React component that synchronizes the Lexical editor state with an external value.
 * It uses the Lexical OnChangePlugin to monitor changes in the editor and updates the external value accordingly.
 * The component serializes the Lexical editor state to an HTML string.
 *
 * @param {CustomOnChangePluginProps} props - The properties for the component.
 * @param {string} props.value - The initial HTML content to be set in the editor.
 * @param {function(string): void} props.onChange - The callback function to be called when the editor content changes.
 * @returns {JSX.Element} The rendered component.
 */
function Index({ value, onChange }: CustomOnChangePluginProps) {
  const [editor] = useLexicalComposerContext();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (!value || !isFirstRender) return;

    setIsFirstRender(false);
    editor.update(() => {
      const currentHTML = $generateHtmlFromNodes(editor);

      if (currentHTML !== value) {
        $getRoot().clear();
        const parser = new DOMParser();
        const dom = parser.parseFromString(value, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);

        $insertNodes(nodes);
      }
    });
  }, [editor, value, isFirstRender]);

  return (
    <OnChangePlugin
      onChange={editorState => {
        editorState.read(() => {
          onChange($generateHtmlFromNodes(editor));
        });
      }}
    />
  );
}

export default Index;
