import React from 'react';
import { Panel, Label } from '@uitk/react';
import { Arrow } from 'builder/scenes/SiteBuilder/SettingsPanel/SettingsPanel';
import {
  SizeLocationData,
  WidgetSizeLocation,
} from '../../../builder/components/WidgetSizeLocation';
import { Config } from './types';
import {
  Padding,
  PaddingData,
} from 'builder/components/WidgetConfigurations/Padding';
import { DividerPanel } from 'builder/components/WidgetConfigurations/DividerPanel/DividerPanel';
import {
  PanelTop,
  SizeLocationPanel,
  StyledPanel,
  StyledPanelTitle,
} from '../Text/TextWidgetControl';
import { thicknessValues } from 'builder/util/constants';
import EnableFullScreenWidth from 'builder/components/WidgetConfigurations/EnableFullScreenWidth';

const DividerWidgetControl: React.FC<WidgetControlProps<Config>> = (
  props: WidgetControlProps<Config>
) => {
  const { value, onChange, widgetState } = props;
  const { direction, lineColor, thickness } = props.widgetState.config;

  const updateWidgetSizeLoction = (sizeLoc: SizeLocationData) => {
    onChange({
      ...value,
      width: sizeLoc.width,
      height: sizeLoc.height,
      xLocation: sizeLoc.xLocation,
      yLocation: sizeLoc.yLocation,
      uniqueId: sizeLoc.uniqueId,
    });
  };
  const updatePaddingData = (data: PaddingData) => {
    onChange({
      ...value,
      paddingData: data,
    });
  };

  const handleLineColor = data => {
    onChange({
      ...value,
      lineColor: data,
    });
  };

  const handleDirection = data => {
    if (data) {
      onChange({
        ...value,
        direction: data,
        width:
          value.direction === data
            ? widgetState.config.width
            : widgetState.config.height,
        height:
          value.direction === data
            ? widgetState.config.height
            : widgetState.config.width,
      });
    }
  };

  const handleThickness = data => {
    if (data) {
      if (direction === 'Horizontal' || !direction) {
        onChange({
          ...value,
          height: thicknessValues[data],
          thickness: data,
        });
      } else {
        onChange({
          ...value,
          width: thicknessValues[data],
          thickness: data,
        });
      }
    }
  };
  const handleFullScreen = (data: boolean) => {
    onChange({
      ...value,
      enableFullScreen: data,
    });
  };
  return (
    <>
      <Panel.Group>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>General</Label>}
            icon={Arrow}
            data-test-id="general-panel"
          >
            <PanelTop>
              <DividerPanel
                data-test-id="general-section-for-divider-component"
                pages={props.pages}
                site={props.site}
                updateSite={props.updateSite}
                saveSite={props.saveSite}
                handleDirection={handleDirection}
                handleLineColor={handleLineColor}
                handleThickness={handleThickness}
                dividerDirection={direction}
                lineColor={lineColor}
                dividerThickness={thickness}
              />
              {direction === 'Horizontal' && (
                <EnableFullScreenWidth
                  handleEnableFullScreen={handleFullScreen}
                  enableFullScreenWidth={widgetState.config.enableFullScreen}
                />
              )}
            </PanelTop>
          </Panel>
        </StyledPanel>
        <StyledPanel>
          <SizeLocationPanel
            title={<Label style={StyledPanelTitle}>Size and Location</Label>}
            initiallyClosed
            icon={Arrow}
            data-test-id="authentication-panel"
          >
            <WidgetSizeLocation
              data-test-id="widget-size-location"
              sizeLocation={{
                xLocation: value.xLocation,
                yLocation: value.yLocation,
                width: value.width,
                height: value.height,
                uniqueId: value.uniqueId,
              }}
              updateWidgetSizeLoction={updateWidgetSizeLoction}
              activeWidgetId={widgetState.id}
            />
          </SizeLocationPanel>
        </StyledPanel>
        <StyledPanel>
          <Panel
            title={<Label style={StyledPanelTitle}>Padding</Label>}
            initiallyClosed
            icon={Arrow}
          >
            <Padding
              data-test-id="padding-data"
              paddingData={updatePaddingData}
              widgetState={widgetState}
            />
          </Panel>
        </StyledPanel>
      </Panel.Group>
    </>
  );
};

export default DividerWidgetControl;
