import { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { doSignInWithLocation } from 'client/util/userManager';
import { withRouter } from 'react-router';
import { defaultTheme as theme } from 'link-ui-react';

// Resolves FlexBug #3
const IE11FlexBugWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthWrapperWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
`;

const AuthWrapperText = styled.div`
  font-size: 2rem;
  font-family: ${p => p.theme.font};
  color: ${theme.colors.aux.darkGrey};
`;

const AuthWrapperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 2px 17px 5px ${theme.colors.aux.darkGrey};
`;

const SignInLink = styled.a`
  text-decoration: underline;
  font-weight: bold;
  color: ${theme.colors.primary};
  cursor: pointer;
`;

const mapStateToProps = state => ({
  user: state.oidc && state.oidc.user,
  session: state.session,
});

export default function authWrapper(WrappedComponent) {
  class AuthWrapperComponent extends PureComponent {
    handleSignIn = () => {
      doSignInWithLocation(this.props.location);
    };
    render() {
      const { user, session, ...rest } = this.props;
      if (!user) {
        return (
          <IE11FlexBugWrapper>
            <AuthWrapperWrapper>
              <AuthWrapperContent>
                {session.silentSigninPending ? (
                  <AuthWrapperText>Loading user...</AuthWrapperText>
                ) : (
                  <AuthWrapperText>
                    Please{' '}
                    <SignInLink
                      onClick={this.handleSignIn}
                      onKeyPress={this.handleSignIn}
                      tabIndex={0}
                    >
                      sign in
                    </SignInLink>{' '}
                    to view this content.
                  </AuthWrapperText>
                )}
              </AuthWrapperContent>
            </AuthWrapperWrapper>
          </IE11FlexBugWrapper>
        );
      } else return <WrappedComponent {...rest} />;
    }
  }
  return withRouter(connect(mapStateToProps)(AuthWrapperComponent));
}
