import { Config } from './types';
import LoginButtonControl from './LoginButtonControl';
import {
  defaultBackgroundData,
  defaultBackgroundImageData,
  defaultBorderData,
  defaultPaddingData,
} from 'builder/util/constants';
import LoginButtonContainer from './LoginButtonContainer';

export default {
  type: 'LoginButton',
  displayName: 'Login Button',
  icon: 'login',
  copyable: false,
  defaultWidget: true,
  initialConfig: {
    ...defaultBackgroundData,
    ...defaultBackgroundImageData,
    ...defaultBorderData,
    ...defaultPaddingData,
    width: 150,
    height: 70,
    xLocation: 1217,
    yLocation: 29,
    minWidth: 20,
    minHeight: 10,
    uniqueId: 'loginButton',
    signInOptionEnabled: 'notEnabled',
    signInOptionTitle: 'Sign In Options',
    signInLinkColor: 'black',
    signInOptionLinks: [],
    lineColor: 'black',
    signInType: 'button',
    signInIcon: 'MoreVertical',
    fontSize: 12,
    font: 'OptumSans',
    buttonColor: '#c0e9ff',
    bold: false,
    italic: false,
    underline: false,
    strikeThrough: false,
    simulateLoggedOffUser: true,
    visible: true,
    profileInLoginDropdownEnabled: 'notEnabled',
  },
  components: {
    render: LoginButtonContainer,
    control: LoginButtonControl,
  },
} as HorizonWidgetDefinition<Config>;
