import React, { useEffect, useState } from 'react';
import { AsyncSelectPaginate } from 'link-ui-react';
import { Label } from '@uitk/react';
import styled from 'styled-components';
import { AskIdConstants } from 'types/types';
import { getAskids } from 'shared/api/askIds';
import { selectStyles } from './AsynSelectStyles';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Link } from '@material-ui/core';
import Tooltip from '../../../../shared/components/Tooltip';

const askIdTooltipContent = (
  <>
    This is your application ID tied to your product.{' '}
    <Link href="https://aide.optum.com" target="_blank">
      Click here
    </Link>{' '}
    for more information.
  </>
);

const SelectWrapper = styled(AsyncSelectPaginate)`
  margin-top: -0.9em;
  padding-top: 15px;
  width: 100%;
  & > div {
    & > div {
      & > div {
        font-family: 'OptumSans';
      }
    }
  }
`;

const StyledLabel = styled(Label)`
  font-family: 'OptumSans';
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: -0.5em;
`;

const SelectAsKIdWrapper = styled.div`
  padding-right: 0px;
`;

export const getAskIdDetails = async function askIdsObject(
  name: string,
  pageNumber: number
) {
  var rootNode: RootObject = {
    results: [],
    pagination: { page: 0, perPage: 25, total: 3 },
  };
  await getAskids(name, pageNumber).then(response => {
    if (response.status === 200) {
      rootNode = response.data;
    }
  });
  return rootNode.results;
};

interface OptionType {
  value: string;
  label: AskIdConstants;
}
export interface CurrentAskIdProps {
  input: {
    onChange: <T>(event: React.ChangeEvent<T> | any) => void;
    value: string;
  };
  defaultAskids?: Array<Result>;
}

const SelectAskId = (props: CurrentAskIdProps) => {
  const { input, defaultAskids } = props;
  const [askId, setAskId] = useState(AskIdConstants.SELECT_A_ASKID.toString());
  const [applicationName, setApplicationName] = useState(
    input.value ? input.value : 'Search for application name'
  );

  const handleAskIdChange = (e: OptionType) => {
    setAskId(e.value);
    setApplicationName(e.label);
    input.onChange(e.value);
  };

  const getAskId = () => {
    return { label: applicationName, value: askId };
  };

  const getOptions = () => {
    return defaultAskids?.map(temp => {
      return {
        value: temp.record.askId,
        label: temp.record.askId + '       ' + temp.record.applicationName,
      };
    });
  };

  const loadAskIds = async (name: string) => {
    const askids =
      name.length === 0
        ? await getAskIdDetails('uhg', 0)
        : await getAskIdDetails(name, 0);
    if (askids.length === 0) {
      askids.push({
        record: {
          askId: AskIdConstants.ASKID_NOT_LISTED.toString(),
          applicationName: AskIdConstants.APP_NOT_LISTED.toString(),
        },
      });
    }

    return {
      options: askids.map(item => ({
        value: item.record.askId,
        label: item.record.askId + '       ' + item.record.applicationName,
      })),
      hasMore: false,
    };
  };

  const handleClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setAskId(askId);
    setApplicationName(applicationName);
  }, [askId, applicationName]);

  return (
    <SelectAsKIdWrapper onClick={handleClick}>
      <StyledLabel data-test-id="select-askId">Ask Id</StyledLabel>
      <Tooltip
        title={askIdTooltipContent}
        arrow
        placement="bottom-start"
        style={{ marginLeft: '8px' }}
        leaveDelay={100}
      >
        <IconButton>
          <InfoOutlinedIcon fontSize="small" style={{ color: 'darkblue' }} />
        </IconButton>
      </Tooltip>
      <SelectWrapper
        onChange={handleAskIdChange}
        options={getOptions()}
        loadOptions={loadAskIds}
        value={getAskId()}
        isClearable={false}
        isSearchable
        styles={selectStyles}
        menuPortalTarget={document.body}
        menuPosition={'absolute'}
        data-test-id={`select-async-askId`}
      />
    </SelectAsKIdWrapper>
  );
};

export default SelectAskId;
