import { ActionCreator, AnyAction, Reducer } from 'redux';
import { Selector, ThunkActionCreator } from 'shared/state';
import { fetchSite as doFetchSite } from 'shared/api/sites';
import { fetchPages as doFetchPages } from 'shared/api/sites';

import _ from 'lodash';

export const SAVE_SITE_PAGES = 'SAVE_SITE_PAGES';
export const SAVE_ONLY_SITE = 'SAVE_ONLY_SITE';
export const SAVE_ONLY_PAGES = 'SAVE_ONLY_PAGES';
export const GET_PAGE = 'GET_PAGE';
export const GET_SINGLE_SITE = 'GET_SINGLE_SITE';
export const SWAP_DATA = 'SWAP_DATA';
export const HAS_CHANGED = 'HAS_CHANGED';
export const RESET_CHANGE = 'RESET_CHANGE';

export interface SiteSlice extends Site {
  pages: Array<Page>;
}

export interface State {
  initialData: SiteSlice;
  newData: SiteSlice;
  hasChanged: Boolean;
}

export const defaultState: State = {
  initialData: {} as SiteSlice,
  newData: {} as SiteSlice,
  hasChanged: undefined as boolean,
};

export const checkLastModifiedDate = (timeStamp1, timeStamp2) => {
  const timeDiff = Math.abs(timeStamp1 - timeStamp2);
  if (timeDiff <= 5000) {
    return true;
  } else {
    return false;
  }
};

export const comparePageJSONObjects = (obj1, obj2) => {
  return _.isEqual(
    obj1.map(page => _.omit(page, ['versions', 'current.forms'])),
    obj2.map(page => _.omit(page, ['versions', 'current.forms']))
  );
};

const checkEqual = (a: SiteSlice, b: SiteSlice) => {
  const hasPagesChanged = comparePageJSONObjects(a.pages, b.pages);
  if (
    checkLastModifiedDate(a.lastModifiedDate, b.lastModifiedDate) &&
    hasPagesChanged
  ) {
    return false;
  } else {
    return true;
  }
};

const withSitePageChanged = (state: State) => {
  return {
    ...state,
    hasChanged: checkEqual(state.initialData, state.newData),
  };
};

const reducer: Reducer<State> = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_SITE_PAGES:
      return {
        ...state,
        initialData: {
          ...action.site,
          pages: action.pages,
        },
      };

    case SAVE_ONLY_SITE:
      return {
        ...state,
        initialData: {
          ...action.site,
          pages: state.initialData.pages,
        },
      };

    case SAVE_ONLY_PAGES:
      return {
        ...state,
        initialData: {
          ...state.initialData,
          pages: action.pages,
        },
      };

    case GET_SINGLE_SITE: {
      return {
        ...state,
        newData: {
          ...action.site,
          pages: state.newData.pages,
        },
      };
    }

    case GET_PAGE:
      return {
        ...state,
        newData: {
          ...state.newData,
          pages: action.pages,
        },
      };

    case HAS_CHANGED:
      return withSitePageChanged(state);

    case SWAP_DATA:
      return {
        ...state,
        initialData: state.newData,
        newData: {} as SiteSlice,
        hasChanged: undefined,
      };

    case RESET_CHANGE:
      return {
        ...state,
        hasChanged: undefined,
      };
    default:
      return state;
  }
};

export default reducer;

export const fetchCompareSiteandPage: ThunkActionCreator = (
  siteId: number
) => dispatch => {
  const promises: Array<Promise<any>> = [];
  promises.push(
    doFetchSite(siteId).then(site => {
      dispatch(getSite(site));
    })
  );
  promises.push(
    doFetchPages(siteId).then(pages => {
      dispatch(getPages(pages));
    })
  );
  Promise.all(promises).then(() => {
    dispatch(checkChange());
  });
};

export const selectInitialSiteDataSlice: Selector<SiteSlice> = () => state => {
  return state.site.initialData;
};

export const saveSitePages: ActionCreator<AnyAction> = (
  site: Site,
  pages: Array<Page>
) => {
  return { type: SAVE_SITE_PAGES, site, pages };
};

export const saveInitialSite: ActionCreator<AnyAction> = (site: Site) => {
  return { type: SAVE_ONLY_SITE, site };
};

export const saveInitialPages: ActionCreator<AnyAction> = (
  pages: Array<Page>
) => {
  return { type: SAVE_ONLY_PAGES, pages };
};

export const getSite: ActionCreator<AnyAction> = (site: Site) => {
  return { type: GET_SINGLE_SITE, site };
};

export const getPages: ActionCreator<AnyAction> = (pages: Array<Page>) => {
  return { type: GET_PAGE, pages };
};

export const checkChange: ActionCreator<AnyAction> = () => {
  return { type: HAS_CHANGED };
};

export const swapResetData: ActionCreator<AnyAction> = () => {
  return { type: SWAP_DATA };
};

export const resetChange: ActionCreator<AnyAction> = () => {
  return { type: RESET_CHANGE };
};
