import axios from 'shared/util/axios';
import {
  PageTemplate,
  PageTemplatePatchRequestBody,
  PageTemplatePostRequestBody,
} from 'shared/state/ducks/pageTemplates';

// Use this URL if working locally with Docker
// export const templateGateway = '/page-templates/v1';

export const templateGateway = '/horizon-template-api-stage';

export enum PageTemplateErrorMessages {
  FetchTemplatesFailed = 'Failed to fetch templates',
  FetchTemplateByIdFailed = 'Failed to fetch template',
  CreateTemplateFailed = 'Failed to create template',
  UpdateTemplateFailed = 'Failed to update template',
  DeleteTemplateFailed = 'Failed to delete template',
}

export async function fetchTemplates(
  tenancies?: string[],
  limit?: number,
  offset?: number
): Promise<Array<PageTemplate>> {
  try {
    const params: {
      tenancies?: string;
      limit?: number;
      offset?: number;
    } = {};

    if (tenancies) {
      params.tenancies = tenancies.join(',');
    }
    if (limit && offset !== undefined) {
      params.limit = limit;
      params.offset = offset;
    }

    const {
      data: { templates = [] } = {},
    } = await axios.get(`${templateGateway}/templates`, { params });
    return templates;
  } catch (error) {
    throw new Error(PageTemplateErrorMessages.FetchTemplatesFailed);
  }
}

export async function createTemplate(
  template: PageTemplatePostRequestBody
): Promise<number | string> {
  try {
    const { data } = await axios.post(`${templateGateway}/template`, template, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data.id;
  } catch (error) {
    throw new Error(PageTemplateErrorMessages.CreateTemplateFailed, error);
  }
}

export async function updateTemplate(
  template: PageTemplatePatchRequestBody
): Promise<PageTemplate> {
  try {
    const { data } = await axios.patch(
      `${templateGateway}/template/${template.id}`,
      template,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error(PageTemplateErrorMessages.UpdateTemplateFailed);
  }
}

export async function getTemplateById(
  templateId: number
): Promise<PageTemplate> {
  try {
    const { data } = await axios.get(
      `${templateGateway}/template/${templateId}`
    );
    return data;
  } catch (error) {
    throw new Error(PageTemplateErrorMessages.FetchTemplateByIdFailed);
  }
}

export async function deleteTemplate(templateId: number): Promise<void> {
  try {
    await axios.delete(`${templateGateway}/template/${templateId}`);
  } catch (error) {
    throw new Error(PageTemplateErrorMessages.DeleteTemplateFailed);
  }
}
