/**
 * Using workaround for https://github.com/babel/babel/issues/8361
 */
import * as _features from './features';
import * as _myapps from './myapps';
import * as _notifications from './notifications';
import * as _org from './org';
import * as _published from './published';
import * as _session from './session';
import * as _sites from './sites';
import * as _site from './site';
import * as _ui from './ui';
import * as _responsive from './responsive';
import * as _provisioningStores from './provisioningStores';
import * as _userSites from './userSites';
import * as _idps from './idps';
import * as _alerts from './alerts';
import * as _undoable from './todos';
import * as _forms from './forms';
import * as _pageTemplates from './pageTemplates';
import * as _tenancies from './tenancies';

const features = _features.default;
const myapps = _myapps.default;
const notifications = _notifications.default;
const org = _org.default;
const published = _published.default;
const session = _session.default;
const sites = _sites.default;
const site = _site.default;
const ui = _ui.default;
const responsive = _responsive.default;
const provisioningStores = _provisioningStores.default;
const userSites = _userSites.default;
const idps = _idps.default;
const alerts = _alerts.default;
const undoabletodos = _undoable.default;
const forms = _forms.default;
const pageTemplates = _pageTemplates.default;
const tenancies = _tenancies.default;

export {
  features,
  myapps,
  notifications,
  org,
  published,
  session,
  sites,
  ui,
  responsive,
  provisioningStores,
  userSites,
  idps,
  alerts,
  undoabletodos,
  site,
  forms,
  pageTemplates,
  tenancies,
};

export type FeaturesState = _features.State;
export type MyAppsState = _myapps.State;
export type NotificationsState = _notifications.State;
export type OrgState = _org.State;
export type PublishedState = _published.State;
export type SessionState = _session.State;
export type SitesState = _sites.State;
export type UIState = _ui.State;
export type ResponsiveState = _responsive.State;
export type ProvisioningStoreState = _provisioningStores.State;
export type UserSites = _userSites.State;
export type IdpState = _idps.State;
export type AlertState = _alerts.State;
export type SiteState = _site.State;
export type FormsState = _forms.State;
export type PageTemplatesState = _pageTemplates.PageTemplateState;
export type TenanciesState = _tenancies.TenanciesState;
