import {
  $applyNodeReplacement,
  $isTextNode,
  DOMConversion,
  DOMConversionMap,
  DOMConversionOutput,
  NodeKey,
  TextNode,
  SerializedTextNode,
  LexicalNode,
} from 'lexical';

/**
 * ExtendedTextNode is a custom node that extends the TextNode and applies styles that are defined in the HTML string.
 * Without this, Lexical will simply serialize the HTML string to Lexical data and use plain text.
 */
export class ExtendedTextNode extends TextNode {
  constructor(text: string, key?: NodeKey) {
    super(text, key);
  }

  /**
   * Returns the type of the node.
   * @returns {string} The type of the node.
   */
  static getType(): string {
    return 'extended-text';
  }

  /**
   * Clones the ExtendedTextNode.
   * @param {ExtendedTextNode} node - The node to clone.
   * @returns {ExtendedTextNode} The cloned node.
   */
  static clone(node: ExtendedTextNode): ExtendedTextNode {
    return new ExtendedTextNode(node.__text, node.__key);
  }

  /**
   * Imports DOM conversion map for the node.
   * @returns {DOMConversionMap | null} The DOM conversion map.
   */
  static importDOM(): DOMConversionMap | null {
    const importers = TextNode.importDOM();
    return {
      ...importers,
      code: () => ({
        conversion: patchStyleConversion(importers?.code),
        priority: 1,
      }),
      em: () => ({
        conversion: patchStyleConversion(importers?.em),
        priority: 1,
      }),
      span: () => ({
        conversion: patchStyleConversion(importers?.span),
        priority: 1,
      }),
      strong: () => ({
        conversion: patchStyleConversion(importers?.strong),
        priority: 1,
      }),
      sub: () => ({
        conversion: patchStyleConversion(importers?.sub),
        priority: 1,
      }),
      sup: () => ({
        conversion: patchStyleConversion(importers?.sup),
        priority: 1,
      }),
    };
  }

  /**
   * Imports a serialized node from JSON.
   * @param {SerializedTextNode} serializedNode - The serialized node.
   * @returns {TextNode} The imported node.
   */
  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return TextNode.importJSON(serializedNode);
  }

  /**
   * Checks if the node is a simple text node.
   * @returns {boolean} True if the node is a simple text node, false otherwise.
   */
  isSimpleText() {
    return this.__type === 'extended-text' && this.__mode === 0;
  }

  /**
   * Exports the node to JSON.
   * @returns {SerializedTextNode} The serialized node.
   */
  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: 'extended-text',
      version: 1,
    };
  }
}

/**
 * Creates an ExtendedTextNode.
 * @param {string} text - The text content of the node.
 * @returns {ExtendedTextNode} The created node.
 */
export function $createExtendedTextNode(text: string): ExtendedTextNode {
  return $applyNodeReplacement(new ExtendedTextNode(text));
}

/**
 * Checks if a node is an ExtendedTextNode.
 * @param {LexicalNode | null | undefined} node - The node to check.
 * @returns {boolean} True if the node is an ExtendedTextNode, false otherwise.
 */
export function $isExtendedTextNode(
  node: LexicalNode | null | undefined
): node is ExtendedTextNode {
  return node instanceof ExtendedTextNode;
}

/**
 * Patches the style conversion for a DOM converter.
 * @param {function(HTMLElement): DOMConversion | null} [originalDOMConverter] - The original DOM converter.
 * @returns {function(HTMLElement): DOMConversionOutput | null} The patched DOM converter.
 */
function patchStyleConversion(
  originalDOMConverter?: (node: HTMLElement) => DOMConversion | null
): (node: HTMLElement) => DOMConversionOutput | null {
  return node => {
    const original = originalDOMConverter?.(node);
    if (!original) {
      return null;
    }
    const originalOutput = original.conversion(node);

    if (!originalOutput) {
      return originalOutput;
    }

    const backgroundColor = node.style.backgroundColor;
    const color = node.style.color;
    const fontFamily = node.style.fontFamily;
    const fontWeight = node.style.fontWeight;
    const fontSize = node.style.fontSize;
    const textDecoration = node.style.textDecoration;

    return {
      ...originalOutput,
      forChild: (lexicalNode, parent) => {
        const originalForChild = originalOutput?.forChild ?? (x => x);
        const result = originalForChild(lexicalNode, parent);
        if ($isTextNode(result)) {
          const style = [
            backgroundColor ? `background-color: ${backgroundColor}` : null,
            color ? `color: ${color}` : null,
            fontFamily ? `font-family: ${fontFamily}` : null,
            fontWeight ? `font-weight: ${fontWeight}` : null,
            fontSize ? `font-size: ${fontSize}` : null,
            textDecoration ? `text-decoration: ${textDecoration}` : null,
          ]
            .filter(value => value != null)
            .join('; ');
          if (style.length) {
            return result.setStyle(style);
          }
        }
        return result;
      },
    };
  };
}
