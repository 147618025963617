import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Icon } from 'link-ui-react';
import Typography from 'shared/components/Typography';
import { defaultTheme as theme } from 'link-ui-react';

const ErrorIcon = styled(Icon).attrs({
  width: '24px',
  height: '24px',
})`
  fill: ${theme.colors.aux.red};
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MessageText = styled(Typography)<{ error: boolean }>`
  color: ${p => (p.error ? theme.colors.aux.red : theme.colors.aux.darkGrey)};
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Message = ({
  children,
  title,
  error = false,
  actions,
}: {
  children: any;
  title?: string;
  error?: boolean;
  actions?: Array<ReactElement<any>>;
}) => (
  <MessageWrapper>
    {error && <ErrorIcon icon="Warning" />}
    <MessageText variant="subheading" title={title} error={error} gutterBottom>
      {children}
    </MessageText>
    <ActionsWrapper>
      {actions &&
        actions.map((action, index) =>
          React.cloneElement(action, { key: index })
        )}
    </ActionsWrapper>
  </MessageWrapper>
);

export default Message;
