import React from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';
import {
  StylesProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
} from '@material-ui/core';
import Loading from 'shared/components/Loading';
import { PageTemplate } from 'shared/state/ducks/pageTemplates';
import { PageTemplateErrorMessages } from '../../../shared/api/pageTemplate';
import PageTemplatesTableHeader from './PageTemplatesHeader';
import PageTemplateTableRow from './PageTemplateTableRow';
import NoPageTemplatesMessage from './NoPageTemplates';
import Message from '../../../shared/components/Message';
import { DeleteTemplateDialog, TemplateDialog } from './TemplateDialogs';

const StyledHeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    border: 1px solid #d3d3d3;
    padding: 8px;
    text-align: left;
    font-weight: bold;
    font-family: OptumSans;
    color: #002677;
    font-size: 25px;
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  justify-content: center;
  padding: 16px;
`;

const StyledTable = styled(Table)`
  border: 1px solid #d3d3d3;
  border-collapse: collapse;
  width: 98%;
`;

const StyledTableRow = styled(TableRow)`
  border: 1px solid #d3d3d3;
`;

const StyledTableCell = styled(TableCell)`
  border: 1px solid #d3d3d3;
  padding: 8px;
  text-align: center;
`;

type PageTemplateListTableProps = {
  error: string;
  pageTemplates: PageTemplate[];
  pending: boolean;
};

export default function PageTemplateList(props: PageTemplateListTableProps) {
  const [templateDialogOpen, setTemplateDialogOpen] = React.useState(false);
  const [
    deleteTemplateDialogOpen,
    setDeleteTemplateDialogOpen,
  ] = React.useState(false);
  const { error, pageTemplates = [], pending } = props;
  const templates = pageTemplates
    // Todo: Remove this filter when the backend is updated to return only templates with status PUBLISHED and DRAFT
    // TA25153019 https://rally1.rallydev.com/#/748968063189d/teamboard?detail=%2Ftask%2F815150551801%2Fdetails
    .filter(template => template.status !== 'DELETED');
  const pageTemplateCount = templates.length;

  const data = templates.map(pageTemplate => {
    return {
      header: 'Your page templates with count zero',
      content: (
        <PageTemplateTableRow
          pageTemplate={pageTemplate}
          onDeleteTemplateClick={setDeleteTemplateDialogOpen}
          onDuplicateTemplateClick={setTemplateDialogOpen}
          onEditDetailsClick={setTemplateDialogOpen}
        />
      ),
    };
  });

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <PageTemplatesTableHeader
            templateCount={pageTemplateCount}
            setTemplateDialogOpen={setTemplateDialogOpen}
          />
        ),
        accessor: 'content',
        Cell: ({ cell: { value } }) => value,
      },
    ],
    [pageTemplateCount]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <StylesProvider injectFirst>
      <TemplateDialog
        templateDialogOpen={templateDialogOpen}
        setTemplateDialogOpen={setTemplateDialogOpen}
      />
      <DeleteTemplateDialog
        deleteTemplateDialogOpen={deleteTemplateDialogOpen}
        setDeleteTemplateDialogOpen={setDeleteTemplateDialogOpen}
      />
      {pending ? (
        <Loading message="Loading page templates..." />
      ) : (
        <StyledTable
          {...getTableProps()}
          data-testid="page-template-list-table"
        >
          <TableHead>
            {headerGroups.map(
              (headerGroup: {
                getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                  TableRowProps;
                headers: any[];
              }) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <StyledHeaderCell {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </StyledHeaderCell>
                  ))}
                </TableRow>
              )
            )}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(
              (row: {
                getRowProps: () => JSX.IntrinsicAttributes & TableRowProps;
                cells: any[];
              }) => {
                prepareRow(row);
                return (
                  <StyledTableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <StyledTableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              }
            )}
          </TableBody>
        </StyledTable>
      )}
      {!pending && pageTemplateCount === 0 && !error && (
        <NoPageTemplatesMessage />
      )}
      {error === PageTemplateErrorMessages.FetchTemplatesFailed && (
        <ErrorWrapper>
          <Message error>{error}</Message>
        </ErrorWrapper>
      )}
    </StylesProvider>
  );
}
