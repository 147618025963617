import styled from 'styled-components';
import {
  default as MUITooltip,
  tooltipClasses,
  TooltipProps as MUITooltipProps,
} from '@mui/material/Tooltip';

interface CustomTooltipProps extends MUITooltipProps {
  textAlign?: string;
}

const Tooltip = styled(
  ({ className, textAlign, ...props }: CustomTooltipProps) => (
    <MUITooltip {...props} classes={{ popper: className }} />
  )
)<{ textAlign?: string }>`
  & .${tooltipClasses.arrow} {
    color: #ffffff;
    &::before {
      background-color: #ffffff;
      border: 1px solid #dadde9;
    }
  }
  & .${tooltipClasses.tooltip} {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
    max-width: 350px;
    font-size: 14px;
    border: 1px solid #dadde9;
    font-family: 'OptumSans', serif !important;
    text-align: ${props => props.textAlign || 'center'};
  }
`;

export default Tooltip;
