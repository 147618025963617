import React, { ReactElement } from 'react';
import { Config } from './types';

const DividerWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const {
    paddingData,
    lineColor,
    visible,
    isCustomWidget,
  } = props.widgetState.config;

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <div
      className="dividerFormatting"
      style={{
        paddingTop: `${paddingData?.top}px`,
        paddingRight: `${paddingData?.right}px`,
        paddingBottom: `${paddingData?.bottom}px`,
        paddingLeft: `${paddingData?.left}px`,
        backgroundColor: `${
          lineColor.startsWith('#') || lineColor.startsWith('rgb')
            ? lineColor
            : 'red'
        }`,
        backgroundImage: `${
          !(lineColor.startsWith('#') || lineColor.startsWith('rgb'))
            ? lineColor
            : 'red'
        }`,
        backgroundClip: 'content-box',
        width: '100%',
        height: '100%',
      }}
    />
  );
};

export default DividerWidget;
