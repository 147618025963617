import Typography from '../../../shared/components/Typography';
import styled from 'styled-components';

const StyledDiv = styled.div`
  border: 1px solid rgb(211, 211, 211);
  border-top: none;
  border-collapse: collapse;
  width: 98%;
  text-align: center;
  padding: 3em;
`;

const NoPageTemplatesMessage = () => (
  <StyledDiv>
    <Typography
      variant="title"
      style={{ margin: '0 0 2em', fontWeight: 'bold' }}
    >
      You do not have any custom templates on your site(s)
    </Typography>
    <Typography variant="plainText">
      Please click the "Create Template" button to create a template for your
      site.
    </Typography>
  </StyledDiv>
);

export default NoPageTemplatesMessage;
