import React, { ReactElement, useState } from 'react';
import { Config } from './types';
import { Icon } from 'link-ui-react';
import styled from 'styled-components';
import {
  BorderData,
  getBorderData,
  getLineColor,
} from 'builder/components/WidgetConfigurations/Border';
import { PaddingData } from 'builder/components/WidgetConfigurations/Padding';
import {
  BackgroundData,
  getBackgroundData,
} from 'builder/components/WidgetConfigurations/Background/Background';
import {
  getBold,
  getCharacterSpacing,
  getFontFamily,
  getFontSize,
  getItalic,
  getLineSpacing,
  getStrikeThrough,
  getTextColor,
  getUnderline,
} from 'builder/components/WidgetConfigurations/Typography/Typography';
import { buttonRadiusMultiplier } from 'builder/util/constants';
import { handleLinkClick } from '../Image/ImageWidget';

const StyledSpan: any = styled.span<{
  borderData: BorderData;
  lineColor: string;
  paddingData: PaddingData;
  backgroundData: BackgroundData;
  backgroundImage: Image;
  currentTab: number;
  hoverBackgroundData: BackgroundData;
  hoverBorderColor: string;
  hoverBorderThickness: number;
  hoverBorderRadius: number;
  hoverPaddingData: PaddingData;
}>`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  border-width: ${p =>
    p.currentTab === 0
      ? p.borderData?.thickness
      : p.hoverBorderThickness
      ? p.hoverBorderThickness
      : 0}px;
  border-style: solid;
  border-color: ${p => p.lineColor};
  border-radius: ${p =>
    p.currentTab === 0
      ? p.borderData?.radius * buttonRadiusMultiplier
      : p.hoverBorderRadius}px;
  padding-top: ${p =>
    p.currentTab == 0 ? p.paddingData.top : p.hoverPaddingData?.top}px;
  padding-right: ${p =>
    p.currentTab == 0 ? p.paddingData.right : p.hoverPaddingData?.right}px;
  padding-bottom: ${p =>
    p.currentTab == 0 ? p.paddingData.bottom : p.hoverPaddingData?.bottom}px;
  padding-left: ${p =>
    p.currentTab == 0 ? p.paddingData.left : p.hoverPaddingData?.left}px;
  background-image: ${p =>
    p.backgroundData?.style === 'Image'
      ? `url('${p => p.backgroundImage?.url}')`
      : ''};
  background: ${p =>
    p.currentTab === 0
      ? p.backgroundData?.color
      : p.hoverBackgroundData?.color};
  background-position: 'center';
  background-repeat: 'no-repeat';
  background-size: 'cover';
  :hover {
    border-width: ${p =>
      p.hoverBorderThickness ? p.hoverBorderThickness : 0}px;
    border-color: ${p => p.hoverBorderColor};
    border-radius: ${p => p.hoverBorderRadius}px;
    padding-top: ${p => p.hoverPaddingData?.top}px;
    padding-right: ${p => p.hoverPaddingData?.right}px;
    padding-bottom: ${p => p.hoverPaddingData?.bottom}px;
    padding-left: ${p => p.hoverPaddingData?.left}px;
    background: ${p =>
      p.hoverBackgroundData?.color ? p.hoverBackgroundData?.color : 'none'};
  }
`;

export const StyledLink: any = styled.a<{
  font: string;
  fontSize: number;
  lineSpacing: number;
  characterSpacing: number;
  textColor: string;
  bold: Boolean;
  italic: Boolean;
  underline: Boolean;
  strikeThrough: Boolean;
  hover: Boolean;
  hoverFontFamily: string;
  hoverBold: Boolean;
  hoverTextColor: string;
  hoverFontSize: number;
  hoverItalic: Boolean;
  hoverUnderline: Boolean;
  hoverStrikeThrough: Boolean;
  hoverCharacterSpacing: number;
  hoverLineSpacing: number;
  widgetType: string;
}>`
  font-family: ${p =>
    p.hoverFontFamily !== undefined
      ? p.hover
        ? p.hoverFontFamily
        : p.font
      : 'OptumSans'};
  font-size: ${p => (p.hover ? p.hoverFontSize : p.fontSize)}pt;
  font-weight: ${p =>
    p.hoverBold !== undefined
      ? p.hover
        ? p.hoverBold
          ? 'bold'
          : 'normal'
        : p.bold
        ? 'bold'
        : 'normal'
      : 'bold'};
  font-style: ${p =>
    p.hover
      ? p.hoverItalic
        ? 'italic'
        : 'normal'
      : p.italic
      ? 'italic'
      : 'normal'};
  text-decoration: ${p =>
    p.hoverUnderline !== undefined
      ? p.hover
        ? p.hoverUnderline
          ? 'underline'
          : p.hoverStrikeThrough
          ? 'line-through'
          : 'none'
        : p.underline
        ? 'underline'
        : p.strikeThrough
        ? 'line-through'
        : 'none'
      : 'underline'};
  line-height: ${p => (p.hover ? p.hoverLineSpacing : p.lineSpacing)};
  letter-spacing: ${p =>
    p.hover ? p.hoverCharacterSpacing : p.characterSpacing}px;
  color: ${p =>
    p.hoverTextColor
      ? p.hover
        ? p.hoverTextColor
        : p.textColor
      : p.widgetType === 'Link'
      ? '#0015fa'
      : p.textColor
      ? p.textColor
      : ''};
  margin: '0.25rem';
  height: 'fit-content';
  width: 'fit-content';
  &:focus {
    outline: 3px solid ${p => p.textColor};
    padding: 3px;
    border-radius: 3px;
  }
`;

export const StyledDiv = styled.div`
  padding: 0 0.25rem;
`;

const LinkWidget: React.FC<WidgetRenderProps<Config>> = (
  props: WidgetRenderProps<Config>
): ReactElement => {
  const { editing, widgetState } = props;
  const {
    displayText,
    paddingData,
    url,
    newTab,
    iconColor,
    backgroundImage,
    currentTab,
    bold,
    visible,
    isCustomWidget,
    linkType,
  } = props.widgetState.config;
  const { hoverData } = props.widgetState.config;
  const [hover, setHover] = useState(false);

  if (visible === false || isCustomWidget) {
    return <></>;
  }

  return (
    <StyledSpan
      borderData={getBorderData(widgetState, currentTab)}
      paddingData={paddingData}
      lineColor={getLineColor(widgetState, currentTab)}
      backgroundData={getBackgroundData(widgetState, currentTab)}
      backgroundImage={backgroundImage}
      currentTab={currentTab ? currentTab : 0}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      hoverBackgroundData={hoverData?.backgroundData}
      hoverBorderColor={hoverData?.lineColor}
      hoverBorderThickness={hoverData?.borderData?.thickness}
      hoverBorderRadius={hoverData?.borderData?.radius * buttonRadiusMultiplier}
      hoverPaddingData={hoverData?.paddingData}
    >
      <StyledLink
        data-test-id="styled-link"
        children={bold ? <strong>{displayText}</strong> : displayText}
        href={editing || url === '' ? null : url}
        target={newTab ? '_blank' : ''}
        font={getFontFamily(widgetState, currentTab)}
        fontSize={getFontSize(widgetState, currentTab)}
        textColor={getTextColor(widgetState, currentTab)}
        characterSpacing={getCharacterSpacing(widgetState, currentTab)}
        lineSpacing={getLineSpacing(widgetState, currentTab)}
        strikeThrough={getStrikeThrough(widgetState, currentTab)}
        italic={getItalic(widgetState, currentTab)}
        bold={getBold(widgetState, currentTab)}
        underline={getUnderline(widgetState, currentTab)}
        currentTab={currentTab ? currentTab : 0}
        hover={hover}
        hoverFontFamily={hoverData?.font}
        hoverBold={hoverData?.bold}
        hoverTextColor={hoverData?.textColor}
        hoverFontSize={hoverData?.fontSize}
        hoverItalic={hoverData?.italic}
        hoverUnderline={hoverData?.underline}
        hoverStrikeThrough={hoverData?.strikeThrough}
        hoverCharacterSpacing={hoverData?.characterSpacing}
        hoverLineSpacing={hoverData?.lineSpacing}
        widgetType={widgetState.type}
        onClick={() => handleLinkClick(linkType, editing)}
      />
      {newTab && (
        <StyledDiv>
          <Icon
            data-test-id="open-in-new-tab-icon"
            icon="NewWindowDarkArrow"
            width={'1em'}
            height={'.9em'}
            viewBox="0 0 15 15"
            style={{ fill: `${iconColor}` }}
            aria-label="Opens in new window/tab"
          />
        </StyledDiv>
      )}
    </StyledSpan>
  );
};
export default LinkWidget;
