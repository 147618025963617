import { Dispatch } from 'shared/state';
import { connect } from 'react-redux';
import { updateCurrentPageWidgetConfig } from 'shared/state/ducks/sites';
import RichTextEditor from './RichTextEditor';

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: { siteId: number; currentPage: { pageId: any } }
) => ({
  updateCurrentPageWidgetConfig: (config: any, widgetId: string) => {
    dispatch(
      updateCurrentPageWidgetConfig(
        ownProps.siteId,
        ownProps.currentPage.pageId,
        config,
        widgetId
      )
    );
  },
});

export default connect(null, mapDispatchToProps)(RichTextEditor);
