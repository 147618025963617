import { ActionCreator, AnyAction, Reducer } from 'redux';
import { Selector, ThunkActionCreator } from 'shared/state';
import { getUserTenancy } from 'shared/api/tenancies';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const FETCH_USER_TENANCY_PENDING =
  'duplo/tenancies/FETCH_USER_TENANCY_PENDING';
export const FETCH_USER_TENANCY_FULFILLED =
  'duplo/tenancies/FETCH_USER_TENANCY_FULFILLED';
export const FETCH_USER_TENANCY_FAILED =
  'duplo/tenancies/FETCH_USER_TENANCY_FAILED';

export interface TenanciesState {
  userTenancy: string | null;
  meta: StateMeta;
}

export const defaultState: TenanciesState = {
  userTenancy: null,
  meta: {
    pending: false,
    successful: false,
    error: null,
  },
};

const reducer: Reducer<TenanciesState> = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_USER_TENANCY_PENDING:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: true,
        },
      };
    case FETCH_USER_TENANCY_FULFILLED:
      return {
        ...state,
        userTenancy: action.userTenancy,
        meta: {
          ...state.meta,
          pending: false,
          successful: true,
          error: null,
        },
      };
    case FETCH_USER_TENANCY_FAILED:
      return {
        ...state,
        meta: {
          ...state.meta,
          pending: false,
          successful: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export const fetchUserTenancyPending: ActionCreator<AnyAction> = () => {
  return { type: FETCH_USER_TENANCY_PENDING };
};

export const fetchUserTenancyFulfilled: ActionCreator<AnyAction> = (
  userTenancy: string
) => {
  return { type: FETCH_USER_TENANCY_FULFILLED, userTenancy };
};

export const fetchUserTenancyFailed: ActionCreator<AnyAction> = (
  error: string
) => {
  return { type: FETCH_USER_TENANCY_FAILED, error };
};

export const fetchUserTenancy: ThunkActionCreator = () => async dispatch => {
  dispatch(fetchUserTenancyPending());
  try {
    const userTenancy = await getUserTenancy();
    dispatch(fetchUserTenancyFulfilled(userTenancy));
  } catch (error) {
    dispatch(fetchUserTenancyFailed(error.message));
    toast('Error occurred while fetching user tenancy', {
      type: 'error',
      theme: 'colored',
    });
  }
};

export const selectTenanciesSlice: Selector<TenanciesState> = () => (
  state
): TenanciesState => {
  return state.tenancies;
};

export const selectUserTenancy: Selector<string | null> = () => state => {
  return selectTenanciesSlice()(state).userTenancy;
};

export const selectTenanciesMeta: Selector<StateMeta> = () => state => {
  return selectTenanciesSlice()(state).meta;
};

export default reducer;
