import { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  FormControl,
  Label,
  LoadingIndicator,
  TextArea,
  TextInput,
} from '@uitk/react';
import styled from 'styled-components';
import { IconInfoCircle } from '@tabler/icons-react';
import { BLANK_TEMPLATE_NAME } from '../../../util/constants';
import * as layoutDefinitions from '../../SiteBuilder/CreateSiteDialog/templates';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { SwitchLovely } from '../../SiteBuilder/SwitchLovely';
import {
  createPageTemplate,
  PageTemplatePostRequestBody,
  PageTemplatePatchRequestBody,
  selectPageTemplatesMeta,
  selectSelectedPageTemplate,
  setSelectedPageTemplate,
  updatePageTemplate,
  clearPageTemplateMeta,
} from '../../../../shared/state/ducks/pageTemplates';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../shared/state';
import { PageTemplateErrorMessages } from '../../../../shared/api/pageTemplate';
import { selectUserTenancy } from '../../../../shared/state/ducks/tenancies';
import Tooltip from '../../../../shared/components/Tooltip';

export const StyledButton = styled(Button)`
  justify-content: center;
  width: 40%;
  & span {
    font-family: OptumSans;
  }
  &:disabled {
    background-color: rgb(204, 204, 204);
    cursor: not-allowed;
    opacity: 0.4;
  }
`;
export const ButtonDiv = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-evenly;
  & > button + button {
    box-shadow: 0 0 0 1px #333333;
  }
`;

export const StyledDialog = styled(Dialog)`
  z-index: 1000;
  .uitk-dialog {
    font-family: OptumSans;
  }
  .uitk-dialog h3:first-of-type {
    display: none;
  }
  & div[class^='Dialogcomponent__Body'] {
    padding: 1rem;
  }
  .uitk-button {
    font-family: OptumSans;
  }
  & > div > div > div + div {
    width: 100%;
    position: relative;
  }
  #text-input {
    font-family: OptumSans;
  }
  & [data-testid^='asterisk-icon'] {
    display: none;
  }
  & div.uitk-dropdown > div > button + div {
    position: fixed;
    width: 15rem;
  }
`;

const StyledImage = styled.img`
  width: 40%;
  padding: 0 0 0.75rem 0;
  align-self: center;
`;

const StyledHeader = styled.h2`
  text-align: center;
  font-family: OptumSans;
  font-weight: 700;
  color: black;
`;

const StyledParagraph = styled.div`
  font-family: OptumSans;
  font-weight: 400;
  font-size: 0.95rem;
  text-align: center;
  padding: 0.5rem 1.4rem;
`;

export const StyledFailureMessage = styled.p`
  font-family: OptumSans;
  font-weight: 400;
  font-size: 1.25rem;
  text-align: center;
  padding: 0.5rem 1.4rem;
  color: #c40000;
`;

const StyledSection = styled.div`
  display: inline-flex;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  padding: 0.5em;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  #required-name-input-err {
    display: none;
  }
  #required-domain-input-err {
    display: inline-flex;
  }
  #dropdown-required-err {
    display: none;
  }
  & > div > select {
    height: 2.9rem;
  }
`;

export const StyledLoaderContainer = styled.div`
  height: 8rem;
`;

const StyledTextInput = styled(TextInput)`
  font-size: 12pt;
  font-family: OptumSans;
`;

const StyledTextArea = styled(TextArea)`
  margin-top: 6px;
  font-size: 12pt;
  font-family: OptumSans;
`;

const StyledFormGroup = styled(FormGroup)`
  padding: 0.5rem 0.5rem 0.5rem 3rem;
  width: 100%;
  & .MuiTypography-body1 {
    font-family: OptumSans;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: -6px;
`;

export function updatePageFromTemplate(template: Preset) {
  return template.widgets;
}

const layoutDefinition = JSON.stringify(
  layoutDefinitions.default.find(layout => layout.name === BLANK_TEMPLATE_NAME)
);

export interface TemplateDialogProps {
  setTemplateDialogOpen: (open: boolean) => void;
  templateDialogOpen: boolean;
}

const TemplateDialog = (props: TemplateDialogProps) => {
  const dispatch = useDispatch();

  const selectedTemplate = useSelector((state: RootState) =>
    selectSelectedPageTemplate()(state)
  );
  const { pending, error } = useSelector((state: RootState) =>
    selectPageTemplatesMeta()(state)
  );
  const userTenancy = useSelector((state: RootState) =>
    selectUserTenancy()(state)
  );

  const layoutDefinitions =
    selectedTemplate?.layoutDefinitions || layoutDefinition;

  const [formIsValid, setFormIsValid] = useState(false);

  const { setTemplateDialogOpen, templateDialogOpen } = props;

  const [formValues, setFormValues] = useState({
    templateName: '',
    templateDescription: '',
    layoutLocked: false,
  });

  const [blurred, setBlurred] = useState({
    templateName: false,
    templateDescription: false,
    layoutLocked: false,
  });

  useEffect(() => {
    if (selectedTemplate) {
      setFormValues({
        templateName: selectedTemplate.title || '',
        templateDescription: selectedTemplate.description || '',
        layoutLocked: selectedTemplate.layoutLocked || false,
      });
    }
  }, [
    selectedTemplate?.title,
    selectedTemplate?.description,
    selectedTemplate?.layoutLocked,
  ]);

  const validateForm = () => {
    const hasNoValue = (value: any): boolean => {
      if (typeof value === 'object' && value !== null) {
        return Object.values(value).some(subValue => hasNoValue(subValue));
      }
      if (typeof value === 'string') {
        return value.trim() === '';
      } else {
        return value === null || value === undefined;
      }
    };

    const isFormCompleted = !Object.values(formValues).some(value =>
      hasNoValue(value)
    );

    const isFormChanged = selectedTemplate
      ? formValues.templateName.trim() !== selectedTemplate.title ||
        formValues.templateDescription.trim() !==
          selectedTemplate.description ||
        formValues.layoutLocked !== selectedTemplate.layoutLocked
      : true;

    const hasError = ['templateName', 'templateDescription'].some(
      input => templateInputError(input) !== ''
    );

    setFormIsValid(isFormCompleted && isFormChanged && !hasError);
  };

  useEffect(() => {
    validateForm();
  }, [formValues, selectedTemplate, blurred]);

  const handleCreateTemplate = async () => {
    const { layoutLocked, templateName, templateDescription } = formValues;
    const newTemplate: PageTemplatePostRequestBody = {
      title: templateName.trim(),
      description: templateDescription.trim(),
      tenancies: [userTenancy],
      layoutDefinitions,
      layoutLocked,
    };
    return dispatch(createPageTemplate(newTemplate));
  };

  const handleUpdateTemplate = async () => {
    const { layoutLocked, templateName, templateDescription } = formValues;
    const newTemplate: PageTemplatePatchRequestBody = {
      title: templateName.trim(),
      description: templateDescription.trim(),
      layoutLocked,
    };
    return dispatch(
      updatePageTemplate({
        ...newTemplate,
        id: selectedTemplate.id,
      })
    );
  };

  const handleSubmitTemplate = async () => {
    try {
      const result = selectedTemplate
        ? await handleUpdateTemplate()
        : await handleCreateTemplate();
      if (result && !error && !pending) {
        handleClose();
      }
    } catch (error) {
      console.error('Error submitting template:', error);
    }
  };

  const stringToBoolean = (str: string): boolean => {
    return str.toLowerCase() === 'true';
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    input: string
  ) => {
    let value: string | boolean = e.target.value;
    if (input === 'layoutLocked') {
      value = stringToBoolean(value);
    }
    setFormValues({
      ...formValues,
      [input]: value,
    });
  };

  const templateInputError = (input: string) => {
    const value = formValues[input].trim();
    if (blurred[input]) {
      switch (input) {
        case 'templateName':
          if (value === '') {
            return 'Template name is required';
          }
          if (value.length < 5 || value.length > 255) {
            return 'Template name must be between 5 and 255 characters';
          }
          break;
        case 'templateDescription':
          if (value === '') {
            return 'Description is required';
          }
          if (value.length > 255) {
            return 'Description must be between 1 and 255 characters';
          }
          break;
        default:
          return '';
      }
    }
    return '';
  };

  const resetForm = () => {
    setFormValues({
      templateName: '',
      templateDescription: '',
      layoutLocked: false,
    });
    setBlurred({
      templateName: false,
      templateDescription: false,
      layoutLocked: false,
    });
  };

  const handleClose = () => {
    setTemplateDialogOpen(false);
    dispatch(setSelectedPageTemplate(null));
    dispatch(clearPageTemplateMeta());
    resetForm();
  };

  const subTitle = selectedTemplate
    ? 'Edit your template'
    : 'Welcome to your new template!';
  const instructions = selectedTemplate
    ? 'Edit your template details and click submit to save your changes.'
    : 'Before we can set up your template, we need just a few details.';

  const tooltipContent = (
    <span>
      <p>Choose the option that best fits how you want to use this template:</p>
      <br />
      <p>
        <strong>Locked:</strong> Prevents changes to the layout when being used.
        Users can only fill in the content, ensuring the design stays
        consistent.
      </p>
      <br />
      <p>
        <strong>Unlocked:</strong> Allows users to customize the layout freely,
        using the template as a flexible starting point.
      </p>
    </span>
  );

  return (
    <span data-test-id="template-dialog-wrapper">
      {templateDialogOpen && (
        <StyledDialog
          data-test-id="create-template-dialog"
          open={templateDialogOpen}
          title={selectedTemplate ? 'Edit Template Details' : 'Create Template'}
          titleAs="h3"
          onClose={handleClose}
        >
          <Dialog.Body>
            {!pending && (
              <>
                <StyledImage
                  src={
                    'https://cdn-stage.linkhealth.com/site-builder/horizon/TemplateSelect.png'
                  }
                />
                <StyledHeader>{subTitle}</StyledHeader>
                <StyledParagraph>{instructions}</StyledParagraph>
                <StyledSection>
                  <StyledFormControl
                    id="required-template-name-input"
                    error={templateInputError('templateName')}
                  >
                    <Label>Template Name</Label>
                    <StyledTextInput
                      data-test-id="template-name-input"
                      placeholder="Enter a template name"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, 'templateName')
                      }
                      onBlur={() =>
                        setBlurred({ ...blurred, templateName: true })
                      }
                      value={formValues.templateName}
                    />
                  </StyledFormControl>
                  <StyledFormGroup data-test-id="layout-locked-toggle-form-group">
                    <Label>
                      Locked Layout
                      <Tooltip
                        title={tooltipContent}
                        arrow
                        textAlign="left"
                        placement="bottom-start"
                        style={{ marginLeft: '8px' }}
                        leaveDelay={100}
                      >
                        <IconInfoCircle
                          stroke={2}
                          size={20}
                          color="#002677"
                          style={{ marginBottom: -4 }}
                        />
                      </Tooltip>
                    </Label>
                    <StyledFormControlLabel
                      control={
                        <SwitchLovely
                          checked={formValues.layoutLocked}
                          onChange={e => handleInputChange(e, 'layoutLocked')}
                          value={!formValues.layoutLocked}
                          data-test-id="layout-locked-toggle-switch"
                        />
                      }
                      label={formValues.layoutLocked ? 'Locked' : 'Unlocked'}
                    />
                  </StyledFormGroup>
                </StyledSection>
                <StyledSection>
                  <StyledFormControl
                    id="required-template-description-input"
                    error={templateInputError('templateDescription')}
                  >
                    <Label>Template Description</Label>
                    <StyledTextArea
                      data-test-id="template-description-input"
                      placeholder="Enter a template description"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(e, 'templateDescription')
                      }
                      onBlur={() =>
                        setBlurred({ ...blurred, templateDescription: true })
                      }
                      value={formValues.templateDescription}
                    />
                  </StyledFormControl>
                </StyledSection>
              </>
            )}
            {pending && (
              <>
                <StyledImage
                  src={
                    'https://cdn-stage.linkhealth.com/site-builder/horizon/CreateSiteLoading.png'
                  }
                />
                <StyledHeader>{`Your template is being ${
                  selectedTemplate ? 'updated' : 'created'
                }...`}</StyledHeader>
                <StyledParagraph>
                  {`Hold tight while we ${
                    selectedTemplate ? 'update' : 'create'
                  } your template`}
                  .
                </StyledParagraph>
                <StyledLoaderContainer>
                  <LoadingIndicator loading={true} size="l" />
                </StyledLoaderContainer>
              </>
            )}
            {(error === PageTemplateErrorMessages.CreateTemplateFailed ||
              error === PageTemplateErrorMessages.UpdateTemplateFailed) && (
              <StyledFailureMessage>
                {`${error}. Please try again. If the issue
                persists, please contact support.`}
              </StyledFailureMessage>
            )}
          </Dialog.Body>
          <Dialog.Actions>
            <ButtonDiv>
              <StyledButton
                data-test-id="cancel-template-create-button"
                onPress={handleClose}
              >
                Cancel
              </StyledButton>
              <StyledButton
                data-test-id="submit-template-create-button"
                inverse
                disabled={!formIsValid}
                onPress={handleSubmitTemplate}
              >
                Submit
              </StyledButton>
            </ButtonDiv>
          </Dialog.Actions>
        </StyledDialog>
      )}
    </span>
  );
};

export default TemplateDialog;
