import DividerWidget from './DividerWidget';
import { Config } from './types';
import DividerWidgetControl from 'shared/widgets/Divider/DividerWidgetControl';

export default {
  type: 'Divider',
  displayName: 'Divider',
  icon: 'Divider',
  initialConfig: {
    width: 360,
    height: 31,
    minWidth: 31,
    minHeight: 31,
    xLocation: 10,
    yLocation: 10,
    uniqueId: '123abc',
    paddingData: {
      top: 15,
      bottom: 15,
      left: 15,
      right: 15,
    },
    direction: 'Horizontal',
    thickness: '1',
    lineColor: 'red',
    enableFullScreen: false,
    visible: true,
  },
  components: {
    render: DividerWidget,
    control: DividerWidgetControl,
  },
  copyable: true,
} as HorizonWidgetDefinition<Config>;
