import styled from 'styled-components';
import {
  duplicateTemplate,
  PageTemplate,
  setSelectedPageTemplate,
} from 'shared/state/ducks/pageTemplates';
import { Dropdown } from 'link-ui-react';
import { useDispatch } from 'react-redux';

const StyledSpan = styled.span`
  font-family: 'OptumSans';
  font-size: 16px;
  color: #8d91a0;
`;

const PageTemplateNameSpan = styled.span`
  font-weight: bold;
  font-family: 'OptumSans';
  font-size: 16px;
  min-width: 260px;
  margin-right: 20px;
`;

const StyledBoldSpan = styled.span`
  font-family: 'OptumSans';
  font-weight: 400;
  color: #000;
  font-size: 16px;
`;

const PageTemplateRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  text-align: left;
`;

const PageTemplateRowInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
`;

const PageTemplateRowContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
`;

interface PageTemplateTableRowProps {
  pageTemplate: PageTemplate;
  onDeleteTemplateClick: (open: boolean) => void;
  onDuplicateTemplateClick: (open: boolean) => void;
  onEditDetailsClick: (open: boolean) => void;
}

const PageTemplateTableRow = ({
  pageTemplate,
  onDeleteTemplateClick,
  onDuplicateTemplateClick,
  onEditDetailsClick,
}: PageTemplateTableRowProps) => {
  const dispatch = useDispatch();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return (
    <PageTemplateRowWrapper
      data-test-id="page-template-row"
      data-template-row-id={pageTemplate.id}
    >
      <PageTemplateRowInnerWrapper>
        <PageTemplateRowContent>
          <PageTemplateNameSpan>{pageTemplate.title}</PageTemplateNameSpan>
          <StyledSpan>
            Description -{' '}
            <StyledBoldSpan>{pageTemplate.description}</StyledBoldSpan>
          </StyledSpan>
        </PageTemplateRowContent>
        <PageTemplateRowContent>
          <StyledSpan>{`Created on ${new Date(
            pageTemplate.createdAt
          ).toLocaleDateString('en-US', options)} -`}</StyledSpan>
          <span>&nbsp;</span>
          {/* TODO Add user name when the back end provides the data */}
          {/* <StyledBoldSpan>{pageTemplate.createdBy}</StyledBoldSpan> */}
        </PageTemplateRowContent>
      </PageTemplateRowInnerWrapper>
      <div>
        <Dropdown
          data-test-id={`site-dropdown`}
          options={[
            {
              title: 'Edit Details',
              onClick: () => {
                onEditDetailsClick(true);
                dispatch(setSelectedPageTemplate(pageTemplate.id));
              },
            },
            // TODO: Implement Edit Template in editor US7721678
            // https://rally1.rallydev.com/#/748968063189d/teamboard?detail=%2Fuserstory%2F811004836747%2Fdetails
            // { title: 'Edit Template' },
            {
              title: 'Delete',
              onClick: () => {
                onDeleteTemplateClick(true);
                dispatch(setSelectedPageTemplate(pageTemplate.id));
              },
            },
            {
              title: 'Duplicate',
              onClick: () => {
                onDuplicateTemplateClick(false);
                dispatch(duplicateTemplate(pageTemplate.id));
              },
            },
          ]}
          iconSize="15"
          border={false}
        />
      </div>
    </PageTemplateRowWrapper>
  );
};

export default PageTemplateTableRow;
